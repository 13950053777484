#popup-thanks {
    .title-3 {
        font-size: 36px;
        color: #1F1F1F;
    }
    .p-18 {
        color: #1F1F1F;
    }
    .p-head {
        padding-bottom: 30px;
    }
    .two-buttons-container{
        justify-content: space-between;
        margin-left: 0px; 
        margin-right: 0px;
    }
    .modal-window{
        max-width: 700px;
        background: #FFFFFF;
        box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
        padding: 50px;
    }
    .btn-large {
        min-width: 230px;
        background: #2292FA;
        font-size: 16px;
        border-radius: 30px;
        color: #FFFFFF;
        padding: 0 35px;
        height: 50px;
        text-transform: none;
    }
    .video-container {
        position: relative;
    	padding-bottom: 56.25%;
    	height: 0;
    	overflow: hidden;
        border-radius: 4px;
        min-width: 288px;
        padding-bottom: 26.25%;
    }
    .social-links {
        border-top: 1px solid rgba(0, 0, 0, .1);
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        padding: 40px 0;

        li {
            margin: 0 20px;
        }

        img {
            height: 80px;
        }
    }
    @include w($sm) {
        .title-3 {
            font-size: 25px;
        }
        .p-18 {
            font-size: 16px;
        }
        .p-head {
            padding-bottom: 20px;
        }
        .modal-window {
            width: 600px;
            padding: 35px 15px 20px;
            max-height: 100vh;
            overflow-y: scroll;
        }
        .video-container {
            margin-bottom: 30px;
            width: 100%;
            padding-bottom: 56.25%;
        }
        .btn-large {
            width: 100%;
        }
        .social-links {
            padding: 20px 0;
    
            li {
                margin: 0 15px;
            }
        }
    }
    @include w($xs) {
        .p-18 {
            font-size: 14px;
            padding-bottom: 5px;
            line-height: 18px;
        }
        // .p-head {
        //     padding-bottom: 10px;
        // }
        .video-container {
            margin-bottom: 10px;
            width: 100%;
            padding-bottom: 56.25%;
        }
        .btn-large {
            width: 100%;
        }
        .social-links {
            padding: 10px 0;
            margin: 10px auto;
            li {
                margin: 0 15px;
            }
        }
        .video-container {
            min-width: 260px;
        }
        .modal-window {
            max-width: 300px;

            .btn-large {
                margin: 0;
            }
        }
    }

}
.accordion {
	.accordionContainer:last-child{
		border-bottom: 2px solid #e4e7ec;
	}
}
.accordionContainer {
	&.active {
		.accordionHeader::after {
			transform: rotate(0);
		}
	}
}
.accordionHeader {
	position: relative;
	min-width: 100%;
	text-align: left;
	cursor: pointer;
	padding: 28px 0;
	border-top: 2px solid #e4e7ec;
	&::after,
	&::before {
		content: "";
		position: absolute;
		margin: auto 0;
		right: 15px;
		top: 0;
		bottom: 0;
		background-color: $blue;
		height: 2px;
		width: 20px;
		transition: 0.3s ease-in-out;
	}
	&::after {
		transform: rotate(-90deg);
	}
	// ------------media-------------
	@include w($sm){
		padding-right: 30px;
	}
}

.accordionInner {
	visibility: hidden;
	max-height: 0;
	overflow: hidden;
    // transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}
.accordionContainer.active .accordionInner {
	max-height: fit-content;
	overflow: auto;
    visibility: visible;
    padding-bottom: 40px;
}
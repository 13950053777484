.slider-style {
    position: relative;

    &__dots {    
		// left: 0;
		// right: 0; 
        bottom: -10px !important;
    }
    &__dot {
        width: 10px !important;
        height: 10px !important;
        margin: 0 6px !important;
        // display: inline-block;
        // border-radius: 100%;
        background: #b1bdcd  !important;
        // border: none;
        opacity: 1  !important;

        &.is-active {
            transform: none !important;
            background: $blue !important;
        }
    }
    &__arrow {
        width: 60px;
		height: 60px;
		background: #FFFFFF;
		box-shadow: 0px 24px 36px rgba(23, 42, 63, 0.08);
		border-radius: 50%;
        
        svg {
            fill: none;
        }
		// ------------media-------------
		@include w($sm){
			display: none;
		}
    }
    &__prev-arrow {
        left: -30px;
        transform: rotate(90deg) scaleX(-1) translate(calc(100% - 20px), 0%);
    }
    &__next-arrow {
        right: -30px;
        transform: rotate(-90deg) scaleX(-1) translate(calc(20px - 100%), 0%);
    }
    .splide__slide {
        box-sizing: border-box;
		height: auto;
		border-radius: 10px;
        box-shadow: 0 10px 15px rgba(23,42,63,.08);

        &--no-shadow {
            box-shadow: none;
        }
    }
    .splide__list {
        padding-bottom: 45px !important;
    }
    .splide.is-initialized:not(.is-active) .splide__list {
        display: flex;
    }
    &.splide:not(.is-overflow) .splide__pagination {
        display: flex;
    }
}
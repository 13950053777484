// .reviews-slider,.review-card{
//     box-shadow: 0px 24px 36px rgba(23, 42, 63, 0.08);
// }
.reviews-slider{
    .review-card{
        background: #FFFFFF;
        padding: 48px;
        height: auto;
        
        &__head{
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $primary;
            padding-bottom: 24px;
            img{
                width: 60px;
                height: 60px;
                border-radius: 50%;
                flex-shrink: 0;
                margin-right: 20px;
            }
        }
        &__body{
            font-size: 16px;
            line-height: 2;
            color: #637B94;

            strong {
                font-weight: 500;
            }
            .review-stars{
                padding-bottom: 24px;
            }
        }
        // ------------media-------------
        @include w($sm){
            padding: 30px;
            &__head{
                flex-direction: column;
                img{
                    margin-right: 0;
                    margin-bottom: 5px;
                }
            }
            &__body{
                .review-stars{
                    margin: 0 auto;
                }
            }
        }
    }
    
}
@import "/src/styles/rebuild/accordion-menu.scss";

.questions-section{
    padding: 50px 0;
    &__left{
        .title-1{
            text-align: left;
			margin-bottom: 100px;
			margin-right: 20px;
			
        }
    }

	// ------------media-------------
	@include w($md){
		&__left{
			padding-bottom: 40px;
			.title-1{
				text-align: center;
				margin-bottom: 40px;
			}
			.p-18{
				text-align: center;
				max-width: 390px;
				margin: 0 auto;
			}
		}
		
	}
}
.partners-section {
	padding: 50px 0;
	.col-3 {
		margin: 15px 0;
	}
	// ------------media-------------
	@include w($sm){
		display: none;
	}
}
.partner-item {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f4f6f8;
	height: 100%;
	padding: 15px;
	img {
		margin: 0 auto;
	}
}
.video-card {
	position: relative;
	cursor: pointer;
	background: $light-2;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	background-color: inherit;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	& + .video-card {
		margin-top: 17px;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
        transition: $t1 linear;
	}
	.youtube-btn {
		width: 60px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.7;
		transition: $t1 linear;

		@include w($xs) {
			width: 48px;
		}
	}
	&:hover {
		.youtube-btn {
			opacity: 1;
        }
        &::before{
            background: rgba(#000000, 0.4);
        }
	}
	&.videoIsLoaded {
		&::before {
			content: none;
		}
		.youtube-btn {
			display: none;
		}
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
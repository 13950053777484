
@import "/src/_includes/components/mobile-platforms/styles/index.scss";
@import "/src/_includes/components/mobile-program/styles/index.scss";
@import "/src/_includes/components/promo-banner/styles/index.scss";
@import "/src/_includes/components/watch-video-button/styles/index.scss";
@import "./first-section-style.scss";

// .bg-index-first-section{
// 	background: linear-gradient(84.48deg, #1e7ae6 0%, #2fa5f1 100%);
// }
.first-section {
	color: #fff;

	strong {
		font-weight: 500;
	}
	
	@extend .first-section-style;
	&.bg-index-first-section{
		background: linear-gradient(84.48deg, #1e7ae6 0%, #2fa5f1 100%);
		.info{
			margin-top: 50px;
			margin-bottom: 60px;
		}
		.title-1 {
			margin-right: 80px;

			@include w($md){
				margin-right: 0;
			}
		}
		.title-4{
			margin-top: 30px;
		}
		// ------------media-------------
		@include w($breakpoint-2){
			.title-1{
				margin-bottom: 20px;
			}
			.info{
				margin-top: 40px;
				margin-bottom: 50px;
			}
			.title-4{
				margin-top: 20px;
			}
		}
		// ------------media-------------
		@include w($lg){
			.title-1{
				font-size: 45px;
			}
			.info{
				margin-top: 20px;
				margin-bottom: 35px;
			}
			.title-4{
				margin-top: 15px;
			}
			.btn-large{
				min-width: 270px;
			}
			.first-section__row{
				justify-content: center;
			}
		} 
		@include w($sm) {
			.title-1 {
				font-size: 27px;
			}
			.title-4{
				margin-top: 0;
				margin-bottom: 20px;
			}
			.title-1+.p-18 {
				margin: 0;
			}
		}
	}
	.info {
		display: flex;
		width: 380px;
		margin: 40px 0;
		align-items: center;
		pointer-events: auto;
		// ------------media-------------
		@include w($md){
			display: none;
		}
		&__desc {
			position: absolute;
			opacity: 0;
			pointer-events: none;
			transition: 0.3s ease-out;
			padding: 15px;
			box-shadow: 0 6px 20px rgba(23, 42, 63, 0.2);
			border-radius: 6px;
			background-color: #fff;
			color: #135;
			bottom: 100%;
			left: 0;
			width: 25vw;
			min-width: 300px;
			font-size: 16px;
			max-width: 310px;
			z-index: 1;
		}
	}
	
	.info__col:hover .info__desc {
		pointer-events: auto;
		opacity: 1;
	}
	
	.info__col:hover .info__img,
	.info__col:hover .info__text {
		opacity: 0.8;
	}
	
	.info__text {
		width: auto !important;
		font-size: 16px !important;
		max-width: 100% !important;
		margin-top: 5px !important;
		transition: 0.3s ease-out;
	}
	
	.info__img {
		height: 40px;
		transition: 0.3s ease-out;
	}
	
	.info__col {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: 40px;
		position: relative;
		padding-top: 20px;
		margin-top: -20px;
	}
	&.first-section-wallet{
		.title-4{
			margin-top: 30px;
		}
		.video-btn {
			display: none;
		}
		// ------------media-------------
		@include w($breakpoint-2){
			.title-1{
				margin-bottom: 20px;
			}
			.title-4{
				margin-top: 20px;
			}
		}
		// ------------media-------------
		@include w($lg){
			.first-section__row{
				justify-content: center;
			}
			.title-1{
				font-size: 45px;
			}
			.title-4{
				margin-top: 15px;
			}
			.btn-large{
				min-width: 270px;
			}
			.rating-link{
				margin-top: 40px;
			}
		}
		@include w($sm) {
			.video-btn {
				display: inline-flex;
				margin-bottom: 30px;
			}

		}
		@include w($xs) {	
			.video-btn {
				min-width: 240px;
			}
		}
	}
	// ------------media-------------
	@include w($md){
		text-align: center;
	}
	// ------------media-------------
	@include w($sm){
		.mobile-platforms{
			padding-top: 0;
		}
		.mobile-item__logo{
			border: none;
		}
	}
	&__row{
		display: flex;
	}
	&__col{
		position: relative;
	}
	&__left{
		min-width: 50%;

		.title-1 {
			max-width: 400px;

			@include w($md) {
				max-width: none;
			}
		}
	}
	&__right{
		min-width: 50%;
		// ------------media-------------
		// @include w($lg){
		// 	min-width: 50%;
		// }
	}

	// ------------media-------------
	@include w($md){
		&__row{
			flex-direction: column;
		}
	}

	.title-1 {
		font-size: 45px;
		margin-bottom: 30px;
		& + .p-18 {
			max-width: 440px;
			font-size: 20px;
			// ------------media-------------
			@include w($lg){
				max-width: 380px;
				font-size: 16px;
			}
			// ------------media-------------
			@include w($md){
				margin: 0 auto;
				margin-bottom: 30px;
			}
		}
	}
	.title-4 {
		max-width: 450px;
		font-size: 19px;
		// ------------media-------------
		@include w(1400px){
			font-size: 16px;
		}
		// ------------media-------------
		@include w($md){
			max-width: none;
		}
	}
	.title-1,
	p,
	.title-4 {
		color: #fff;
		text-align: left;
		margin-top: 20px;
		// ------------media-------------
		@include w($md){
			text-align: center;
		}
	}
	.rating-link{
		margin-top: 70px;
		margin-bottom: 20px;
			// ------------media-------------
		@include w($md){
			margin-top: 20px;
			justify-content: center;
		}
	}
	.rating-link{
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.07em;
		text-transform: uppercase;
		img{
			margin-right: 20px;
		}
		
	}
	.laptop-video {
		position: relative;
		height: 100%;
		min-height: 400px;
		margin-top: 22px;
		// ------------media-------------
		@include w($lg){
			min-height: auto;
			margin-top: 0;
		}
		&__img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: none;
			width: 1100px;
	
			// ------------media-------------
			@include w($breakpoint-1){
				width: 950px;
			}
			// ------------media-------------
			@include w($breakpoint-2){
				width: 810px;
			}
			// ------------media-------------
			@include w($lg){
				width: 720px;
				top: 45%;
			}
			@include w($md){
				position: static;
				top: auto;
				left: auto;
				transform: none;
				width: 100%;
				max-width: none;
				min-width: none;
			}
			@include w($sm) {
				display: none;
			}
		}
		picture source {
			@include w($sm) {
				display: none;
			}
		}
	}
	
	.laptop__bottom {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 10;
		position: absolute;
		bottom: -130px;
		// ------------media-------------
		@include w($breakpoint-1){
			bottom: -100px;
		}
		// ------------media-------------
		@include w($breakpoint-2){
			bottom: -55px;
		}
		// ------------media-------------
		@include w($lg){
			bottom: -5px;
		}
		// ------------media-------------
		@include w($md){
			display: none;
		}
	}
	
	.laptop-stars {
		display: flex;
		align-items: center;
		color: #fff;
		margin-right: 16px;
	}
	
	.laptop-stars__title {
		font-size: 18px;
		line-height: 1;
		margin: 0 16px 0 0;
		// ------------media-------------
		@include w($lg){
			font-size: 14px;
		}
	}
	
	.laptop-stars__list {
		margin: 0;
		display: flex;
		align-items: center;
	}
	
	.laptop-stars__item {
		margin-right: 2px;
	}
	
	.laptop-stars__item img {
		display: block;
	}
	
	.laptop-reviews {
		display: flex;
		align-items: center;
	}
	
	.laptop-reviews__title {
		color: #fff;
		font-weight: 400;
		font-size: 18px;
		line-height: 1;
		margin-right: 16px;
		margin-top: 0;
		// ------------media-------------
		@include w($lg){
			margin-right: 0;
			font-size: 14px;
		}
	}
	
	// .laptop-reviews__title strong {
	// 	font-weight: 700;
	// }
	
	.laptop-reviews__img {
		display: block;
		width: 118px;
		transform: translateY(-2px);
	}
	
}
#popup-form {
    .description {
        display: flex;
        gap: 30px;
        justify-content: space-between;
        padding-bottom: 15px;

        &__img {
            max-height: 70px;
        }
        .title-3, .p-18 {
            color: #1F1F1F;
            text-align: left;
        }
        .subscribe-form{
            flex-direction: column;
            
        }
        .subscribe-control{
            width: auto;
            &+.subscribe-control{
                margin-top: 12px;
            }
        }
        @include w($sm) {
            gap: 15px;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 5px;

            .title-3, .p-18 {
                text-align: center;
            }
        }
    }
}
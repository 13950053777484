// colors
$primary:   	#113355;
$secondary: 	#B1BDCD;
$primary-staking:#131313;
$parag:     	#637b94;
$dark-blue: 	#373f5a;
$blue:      	#3692fe;
$light:     	#f5f9fb;
$light-2:   	#b1bdcd;
$light-3:   	#fbfbfd;
$orange:    	#f39200;
// $a:             #e7f4fe;
$big-shadow: 0px 24px 48px rgba(23, 42, 63, 0.16);
$shadow: 0px 16px 32px rgba(23, 42, 63, 0.24);
$border: 1px solid rgba(255,255,255,.24);
// media
$lg: 1366px;	
$md: 1200px;
$sm: 768px;
$xs: 380px;

$t1: 0.1s;

// ========================mixin==============================
// how use
// @include fluid-type($min_width, $max_width, 12px, 16px);
// /how use

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(
					#{$min-font-size} + #{strip-unit(
							$max-font-size - $min-font-size
						)} *
						(
							(100vw - #{$min-vw}) / #{strip-unit(
									$max-vw - $min-vw
								)}
						)
				);
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

@mixin w($size, $sizeMin: 0px) {
	@if ($sizeMin == 0) {
		@media (max-width: ($size - 0.02)) {
			@content;
		}
	} @else {
		@media (min-width: $sizeMin) and (max-width: ($size - 0.02)) {
			@content;
		}
	}
}

@mixin h($size) {
	@media (max-height: $size) {
		@content;
	}
}

@mixin offHoverOnDevices {
	@media screen and (min-width: $md) {
		@content;
	}
}

@mixin minw($size) {
	@media (min-width: $size) {
		@content;
	}
}
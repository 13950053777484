.popup-subscribe {
    .modal-window {
        max-width: 920px;
        background: #0074b4;
        border-radius: 0;
        padding: 10px 128px 43.6px;

        // ------------media-------------
        @include w($sm){
            padding: 10px 60.3px 35.3px;
        }
        @include w(500px) {
            padding: 10px 25.3px 15.3px;
        }
    }
    .subscribe-form {
        flex-direction: column;
        width: 100%;
        gap: 14.3px;
        max-width: 461.3px;
        align-items: center;
        margin: 18.6px auto 0;
        // ------------media-------------
        @include w($sm){
            gap: 26.6px;
            margin: 39.6px auto 0;
        }
        @include w(500px) {
            gap: 10.5px;
            margin: 15px auto 0;
        }
    }
    .subscribe-email {
        box-shadow: 5px 5px 10px 0px #00000040;
        border-radius: 30px;
        color: #1F2843;
        border: 2px solid transparent;
        padding-left: 27px;
        padding-right: 27px;
        &::placeholder{
            color: #1F2843;
        }
    }
    .subscribe-email:focus,.subscribe-email:hover{
        border-color: #01BBF5;
    }
    .subscribe-control{
        font-size: 16.6px;
        line-height: 20.6px;
        height: 48px;
        margin: 0;
        transition: $t1 linear;
        // ------------media-------------
        @include w($md){
            margin: 0px;
            &+.subscribe-control{
                margin-top: 0;
            }
        }
        // ------------media-------------
        @include w($sm){
            font-size: 20px;
        }
    }
    .subscribe-button {
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 30px;
        max-width: 218px;
        padding: 14px 50px;
        background: #01BBF5;

        // @include w($md) {
        //     margin-top: 0;
        // }
    }
    .subscribe-button:hover {
        box-shadow: 0 24px 36px rgba(23,42,63,.08);
        background: #1d72da;
    }
    &__img {
        margin: 0 auto;
        width: 250px;
    }
    &__title {
        font-size: 36px;
        line-height: 34.56px;
        color: #ffffff;
        margin: 13.79px 0 25px;

        @include w($sm) {
            font-size: 31px;
            line-height: 30px;
            margin: 5.79px 0 14.6px;
        }
    }
    &__text {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: #ffffff;

        strong {
            font-weight: 700;
        }

        @include w($sm) {
            font-size: 16px;
            line-height: 22.3px;
        }
    }
    &__text--highlight {
        color: #FFC02E;
        margin-bottom: 10px;

        @include w($sm) {
            margin-bottom: 5px;
        }
    }
}
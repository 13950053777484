$sprites: (
    'academy': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.636 9.255v3.636L10 16.364l6.364-3.473V9.255L10 12.727 3.636 9.255ZM10 0 0 5.455l10 5.454 8.182-4.464v6.282H20V5.455L10 0Z' fill='url(%23a)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='0' y1='18.5' x2='18' y2='8' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23F05757'/%3e%3cstop offset='1' stop-color='%23F18C43'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'airdrop': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 8.614C.45 3.982 4.777 0 10.048 0c5.248 0 9.56 3.947 10.04 8.553.007.064-.026.117-.076.152a.498.498 0 0 1-.133.176L13.014 14.7V20H7.082v-5.264L.23 8.88a.502.502 0 0 1-.076-.08c-.088-.023-.162-.091-.153-.186Zm.92-.46 6.006 5.133-.958-4.595c-.587-.603-1.592-1.37-2.906-1.37-.937 0-1.643.39-2.143.832Zm6.084.609 1.107 5.305h3.83l1.115-5.348c-.551-.656-1.565-1.544-3.009-1.544-1.476 0-2.501.928-3.043 1.587Zm9.902-1.441c1.025 0 1.768.391 2.28.835l-6.063 5.14.977-4.688c.525-.588 1.436-1.287 2.806-1.287Z' fill='url(%23a)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='0' y1='20' x2='21.824' y2='17.882' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%231E7AE6'/%3e%3cstop offset='1' stop-color='%232FA5F1'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'arrow': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 5.586 1.707.293.293 1.707 7 8.414l6.707-6.707L12.293.293 7 5.586Z' fill='%23637B94'/%3e%3c/svg%3e",
    'assets-status': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='20.845' y1='0' x2='20.845' y2='68' gradientTransform='scale(.30545 .29412)'%3e%3cstop stop-color='%2323D4FB'/%3e%3cstop offset='1' stop-color='%232394FB'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:nonzero%3bfill:url(%23a)' d='M11.16 18.828 10.05 7.422h.907V6.25h-1.02l-.152-1.563h1.172V3.516H9.68c-.114-1.11-1.153-2.024-2.528-2.278V0h-1.52v1.238c-1.374.254-2.413 1.168-2.527 2.278H1.828v1.171h1.168L2.848 6.25h-1.02v1.172h.906L1.63 18.828H0V20h12.734v-1.172Zm-4.008 0h-1.52v-2.344h1.52ZM4.375 6.25l.148-1.563h3.739l.152 1.563Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'assets': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M15.357 10.952c1.924 0 3.555-.542 4.643-1.436v.722c0 1.629-1.996 2.857-4.643 2.857s-4.643-1.228-4.643-2.857v-.722c1.087.894 2.718 1.436 4.643 1.436Z' fill='%233692FE'/%3e%3cpath d='M15.357 7.381c1.924 0 3.555-.542 4.643-1.437v.723c0 1.629-1.996 2.857-4.643 2.857s-4.643-1.228-4.643-2.857v-.723c1.087.895 2.718 1.437 4.643 1.437ZM10.714 2.857C10.714 1.228 12.71 0 15.357 0S20 1.228 20 2.857c0 1.629-1.996 2.857-4.643 2.857s-4.643-1.228-4.643-2.857ZM9.286 6.667c0 1.629-1.996 2.857-4.643 2.857S0 8.296 0 6.667C0 5.037 1.996 3.81 4.643 3.81s4.643 1.228 4.643 2.857Z' fill='%233692FE'/%3e%3cpath d='M4.643 10.952c1.924 0 3.556-.542 4.643-1.436v.722c0 1.629-1.996 2.857-4.643 2.857S0 11.867 0 10.238v-.722c1.087.894 2.719 1.436 4.643 1.436Z' fill='%233692FE'/%3e%3c/svg%3e",
    'blockchain': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23a)' fill='white'%3e%3cpath d='M25.205 6.511c0-1.04-.952-1.886-2.122-1.886-.601 0-1.144.224-1.53.581L13.27.956a.56.56 0 0 0-.505 0l-8.281 4.25a2.25 2.25 0 0 0-1.53-.581c-1.17 0-2.122.846-2.122 1.886 0 .885.69 1.63 1.616 1.832v8.496c0 .16.097.308.253.389l8.279 4.248a1.686 1.686 0 0 0-.085.527c0 1.04.952 1.886 2.122 1.886s2.122-.846 2.122-1.886c0-.183-.03-.36-.085-.526l8.28-4.25a.443.443 0 0 0 .253-.388V8.343c.927-.202 1.617-.947 1.617-1.832Zm-23.362 0c0-.545.499-.988 1.112-.988.612 0 1.111.443 1.111.988 0 .545-.499.988-1.111.988-.613 0-1.112-.443-1.112-.988Zm11.175 16.48c-.613 0-1.112-.443-1.112-.988 0-.545.499-.988 1.112-.988.613 0 1.112.443 1.112.988 0 .545-.5.988-1.112.988Zm1.531-2.292a2.25 2.25 0 0 0-1.531-.582 2.25 2.25 0 0 0-1.53.582l-8.028-4.12V8.343c.927-.202 1.617-.947 1.617-1.832 0-.183-.03-.36-.086-.528l8.028-4.119 8.027 4.12a1.686 1.686 0 0 0-.085.527c0 .885.69 1.63 1.617 1.832v8.236l-8.029 4.12Zm8.534-13.2c-.613 0-1.112-.443-1.112-.988 0-.545.499-.988 1.112-.988.613 0 1.111.443 1.111.988 0 .545-.498.988-1.111.988Z'/%3e%3cpath d='m19.94 7.863-6.669-3.422a.56.56 0 0 0-.505 0l-6.67 3.422a.443.443 0 0 0-.252.389v6.845c0 .16.097.308.253.389l6.67 3.422a.555.555 0 0 0 .504 0l6.67-3.423a.443.443 0 0 0 .253-.388V8.252a.443.443 0 0 0-.253-.389ZM13.02 5.348l5.659 2.904-5.66 2.904L7.36 8.252l5.659-2.904Zm.505 12.393v-2.025c0-.248-.226-.45-.505-.45-.28 0-.505.202-.505.45v2.025l-5.66-2.904V9.03l5.912 3.033a.555.555 0 0 0 .505 0l5.912-3.033v5.807l-5.659 2.904Z'/%3e%3cpath d='M13.018 13.246a.544.544 0 0 0-.358.132.428.428 0 0 0-.148.317c0 .118.054.234.148.317a.544.544 0 0 0 .358.132.542.542 0 0 0 .357-.132.428.428 0 0 0 .148-.317.428.428 0 0 0-.148-.317.542.542 0 0 0-.357-.132Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' transform='translate(.075 .941)' d='M0 0h25.869v22.992H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
    'blog': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.667 3.333H0V15c0 .917.75 1.667 1.667 1.667h11.666V15H1.667V3.333ZM15 0H5c-.917 0-1.667.75-1.667 1.667v10c0 .916.75 1.666 1.667 1.666h10c.917 0 1.667-.75 1.667-1.666v-10C16.667.75 15.917 0 15 0Zm-.833 7.5H5.833V5.833h8.334V7.5Zm-3.334 3.333h-5V9.167h5v1.666Zm3.334-6.666H5.833V2.5h8.334v1.667Z' fill='url(%23a)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='-.668' y1='17.333' x2='16.332' y2='.333' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%231FA4ED'/%3e%3cstop offset='0' stop-color='%231FA3EC'/%3e%3cstop offset='1' stop-color='%231CCAD6'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'book-outline': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 1a1 1 0 0 1 1-1h6a5 5 0 0 1 5 5v14a1 1 0 1 1-2 0 2 2 0 0 0-2-2H1a1 1 0 0 1-1-1V1Zm10 14.536V5a3 3 0 0 0-3-3H2v13h6a4 4 0 0 1 2 .536Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.464 1.464A5 5 0 0 1 15 0h6a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1h-7a2 2 0 0 0-2 2 1 1 0 1 1-2 0V5a5 5 0 0 1 1.464-3.536ZM12 15.536A4 4 0 0 1 14 15h6V2h-5a3 3 0 0 0-3 3v10.536Z' fill='white'/%3e%3c/svg%3e",
    'dapps': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23a)' fill-rule='evenodd' clip-rule='evenodd'%3e%3cpath d='M16.25 11.7a.625.625 0 0 0-.176-.437.625.625 0 0 0-.437-.175.625.625 0 0 0-.438.175.625.625 0 0 0-.175.437v1.425c0 .35-.275.625-.625.625H5.624A.619.619 0 0 1 5 13.125V11.7a.625.625 0 0 0-.175-.437.625.625 0 0 0-.437-.175.625.625 0 0 0-.438.175.625.625 0 0 0-.175.437v4.55c0 .688.563 1.25 1.25 1.25h10c.688 0 1.25-.562 1.25-1.25V11.7h-.025Z' fill='url(%23b)'/%3e%3cpath d='M15.3 3.313a1.258 1.258 0 0 0-1.174-.813H5.863c-.525 0-.987.325-1.175.813l-1.012 2.7c-.113.287-.338.512-.563.724a1.885 1.885 0 0 0-.625 1.4c0 1.038.838 1.875 1.875 1.875a1.872 1.872 0 0 0 1.875-1.875c0 1.038.838 1.875 1.875 1.875a1.872 1.872 0 0 0 1.875-1.875c0 1.038.838 1.875 1.875 1.875a1.872 1.872 0 0 0 1.875-1.875c0 1.038.838 1.875 1.875 1.875a1.872 1.872 0 0 0 1.875-1.875c0-.55-.237-1.05-.625-1.4-.225-.212-.45-.437-.562-.724l-1.013-2.7h.013Z' fill='url(%23c)'/%3e%3c/g%3e%3cdefs%3e%3clinearGradient id='b' x1='3.774' y1='17.322' x2='11.725' y2='7.183' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%236B69F2'/%3e%3cstop offset='1' stop-color='%239A5FD6'/%3e%3c/linearGradient%3e%3clinearGradient id='c' x1='2.488' y1='9.804' x2='11.747' y2='-2.29' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%236B69F2'/%3e%3cstop offset='1' stop-color='%239A5FD6'/%3e%3c/linearGradient%3e%3cclipPath id='a'%3e%3cpath fill='white' transform='translate(2.5 2.5)' d='M0 0h15v15H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
    'desktop-wallet': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect y='15.6' width='20' height='1.4' rx='.7' fill='url(%23a)'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 3a1.4 1.4 0 0 0-1.4 1.4v8.4A1.4 1.4 0 0 0 3 14.2h14a1.4 1.4 0 0 0 1.4-1.4V4.4A1.4 1.4 0 0 0 17 3H3Zm4.9 1.4a.7.7 0 1 0 0 1.4h4.2a.7.7 0 1 0 0-1.4H7.9Z' fill='url(%23b)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='20' y1='15.6' x2='19.947' y2='17.546' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%2342C1FD'/%3e%3cstop offset='1' stop-color='%231F8EFA'/%3e%3c/linearGradient%3e%3clinearGradient id='b' x1='18.401' y1='3' x2='14.602' y2='17.586' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%2342C1FD'/%3e%3cstop offset='1' stop-color='%231F8EFA'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'download': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m15.934 9.518-5.677 5.046L4.58 9.518l1.044-.928 3.894 3.462V.382h1.477v11.67l3.894-3.462 1.045.928Zm3.773 6.349H.807v1.312h18.9v-1.312Z' fill='white'/%3e%3c/svg%3e",
    'explorer': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.707.445 1.676 3.96l7.031 3.515 7.032-3.515L8.707.445Z' fill='url(%23a)'/%3e%3cpath d='M7.532 9.351.501 5.835v8.204l7.031 3.516V9.35Z' fill='url(%23b)'/%3e%3cpath d='M16.908 5.835 9.877 9.351v8.204L17 13.888l-.092-8.053Z' fill='url(%23c)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='1.676' y1='4.039' x2='15.739' y2='4.039' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23169DC9'/%3e%3cstop offset='1' stop-color='%2335C192'/%3e%3c/linearGradient%3e%3clinearGradient id='b' x1='.501' y1='11.825' x2='7.532' y2='11.825' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23169DC9'/%3e%3cstop offset='1' stop-color='%2335C192'/%3e%3c/linearGradient%3e%3clinearGradient id='c' x1='9.877' y1='11.825' x2='17' y2='11.825' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23169DC9'/%3e%3cstop offset='1' stop-color='%2335C192'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'fb': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.587 16.153v-7.68h2.245l.298-2.647H5.587l.004-1.324c0-.69.07-1.06 1.12-1.06h1.403V.793H5.868c-2.697 0-3.646 1.284-3.646 3.443v1.59H.541v2.646h1.681v7.68h3.365Z' fill='url(%23a)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='.541' y1='16.153' x2='8.844' y2='15.756' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%231E7AE6'/%3e%3cstop offset='1' stop-color='%232FA5F1'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'AUD': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='-23.5' x2='33' y2='92' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%23dd383d%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23253692%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M0 18C0 8.059 8.059 0 18 0s18 8.059 18 18-8.059 18-18 18S0 27.941 0 18Zm8.652 3.098h5.836l1.23 3.55h2.735L12.75 9.387h-2.352L4.707 24.648H7.43Zm5.102-2.141H9.387l2.183-6.332Zm12.805.242c.37.383.558.871.558 1.469 0 .633-.207 1.145-.62 1.527-.411.387-1.005.578-1.794.578-.8 0-1.422-.242-1.863-.722-.434-.485-.652-1.164-.652-2.047h-2.512c0 1.387.363 2.496 1.094 3.336.738.84 1.793 1.336 3.16 1.488v2.035h1.644v-2.047c1.278-.132 2.27-.566 2.98-1.3.72-.73 1.075-1.688 1.075-2.871 0-.63-.09-1.176-.277-1.645a3.787 3.787 0 0 0-.797-1.238 5.272 5.272 0 0 0-1.274-.965c-.504-.277-1.195-.582-2.082-.91-.883-.328-1.508-.676-1.883-1.04-.363-.37-.546-.859-.546-1.468 0-.676.183-1.195.558-1.559.371-.363.89-.547 1.55-.547.673 0 1.2.254 1.587.766.39.504.59 1.195.59 2.074h2.504c0-1.367-.329-2.48-.985-3.332-.656-.86-1.582-1.379-2.785-1.55V6.92h-1.652v2.286c-1.196.14-2.14.586-2.836 1.332-.7.75-1.047 1.703-1.047 2.863 0 1.711.805 3.004 2.41 3.88.484.265 1.16.554 2.031.866.867.309 1.489.66 1.864 1.051Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'CAD': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='32.5' y1='0' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:white%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23fc3d1d%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18Zm-24.992 4.805c.777-.68 1.226-1.621 1.34-2.828h-1.965c-.106.808-.352 1.382-.742 1.73-.391.348-.973.523-1.746.523-.848 0-1.493-.324-1.934-.964-.438-.645-.656-1.575-.656-2.801v-1c.011-1.207.246-2.125.703-2.746.46-.625 1.12-.942 1.98-.942.739 0 1.297.184 1.676.547.387.356.625.942.719 1.754h1.965c-.125-1.238-.57-2.195-1.332-2.875-.766-.68-1.774-1.02-3.028-1.02-.93 0-1.754.223-2.465.66-.707.442-1.25 1.071-1.632 1.884-.38.812-.567 1.753-.567 2.824v1.054c.016 1.043.211 1.957.582 2.747.375.785.906 1.394 1.594 1.828.691.422 1.488.636 2.395.636 1.293 0 2.332-.34 3.113-1.011Zm5.07-1.782h4.399l.93 2.637h2.058l-4.297-11.32h-1.773l-4.29 11.32h2.051Zm3.844-1.585h-3.29l1.645-4.696Zm4.836-7.098v11.32h3.27c1.023 0 1.933-.219 2.722-.66a4.482 4.482 0 0 0 1.832-1.883c.434-.812.648-1.758.648-2.828v-.57c0-1.055-.214-1.992-.64-2.813a4.538 4.538 0 0 0-1.809-1.898c-.773-.445-1.664-.668-2.668-.668Zm1.976 9.75v-8.164h1.38c1.019 0 1.796.324 2.331.972.535.649.805 1.586.805 2.813v.633c-.012 1.207-.293 2.133-.852 2.781-.55.645-1.343.965-2.378.965Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'CHF': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='32.5' y1='0' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%23fc3d1d%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23d70a2b%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18Zm-24.996 4.805c.777-.68 1.226-1.621 1.34-2.828h-1.965c-.106.808-.352 1.382-.742 1.73-.387.348-.969.523-1.746.523-.848 0-1.493-.324-1.934-.964-.434-.645-.652-1.575-.652-2.801v-1c.008-1.207.242-2.125.699-2.746.465-.625 1.125-.942 1.98-.942.739 0 1.297.184 1.676.547.387.356.625.942.719 1.754h1.965c-.125-1.238-.57-2.195-1.332-2.875-.766-.68-1.774-1.02-3.028-1.02-.93 0-1.75.223-2.46.66-.708.442-1.25 1.071-1.633 1.884-.38.812-.567 1.753-.567 2.824v1.054c.016 1.043.211 1.957.582 2.747.375.785.906 1.394 1.594 1.828.691.422 1.488.636 2.39.636 1.298 0 2.333-.34 3.114-1.011Zm10.156.855h1.965V12.34H21.16v4.71h-5.074v-4.71h-1.973v11.32h1.973V18.63h5.074Zm6.277-4.797h4.57v-1.57h-4.57v-3.367h5.239V12.34h-7.211v11.32h1.973Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'CZK': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='0' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:white%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%233e53b4%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18Zm-25.016 4.805c.778-.68 1.223-1.621 1.336-2.828h-1.957c-.105.808-.351 1.382-.738 1.73-.39.348-.973.523-1.742.523-.848 0-1.488-.324-1.93-.964-.437-.645-.656-1.575-.656-2.801v-1c.012-1.207.246-2.125.703-2.746.46-.625 1.117-.942 1.973-.942.738 0 1.297.184 1.675.547.383.356.622.942.715 1.754h1.957c-.12-1.238-.566-2.195-1.328-2.875-.762-.68-1.77-1.02-3.02-1.02-.925 0-1.745.223-2.456.66-.704.442-1.246 1.071-1.625 1.884-.38.812-.567 1.753-.567 2.824v1.054c.016 1.043.207 1.957.582 2.747.375.785.903 1.394 1.586 1.828.692.422 1.488.636 2.39.636 1.29 0 2.325-.34 3.102-1.011Zm11.004-.715h-6.05l5.867-8.504V12.34h-8.207v1.586h5.816l-5.855 8.46v1.274h8.43Zm3.664-1.961 1.285-1.36 3.407 4.891h2.332l-4.45-6.305 4.212-5.015h-2.383l-3.313 3.965-1.09 1.343V12.34h-1.965v11.32h1.965Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'DKK': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='-13' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:white%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23fc3d1d%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18ZM3.324 12.184v11.418H6.61c1.032 0 1.942-.22 2.739-.665a4.516 4.516 0 0 0 1.84-1.898c.437-.82.652-1.773.652-2.855v-.57c0-1.067-.215-2.016-.645-2.84a4.572 4.572 0 0 0-1.816-1.915c-.781-.449-1.676-.675-2.684-.675ZM5.31 22.02v-8.235h1.386c1.024 0 1.805.324 2.344.98.54.653.809 1.598.809 2.84v.633c-.012 1.219-.297 2.157-.856 2.809-.555.648-1.351.973-2.39.973ZM15.8 20.043l1.293-1.371 3.437 4.93h2.352l-4.485-6.36 4.243-5.058h-2.403l-3.34 4-1.097 1.359v-5.36h-1.985v11.419h1.985Zm10.133 0 1.293-1.371 3.437 4.93h2.352l-4.489-6.36 4.246-5.058h-2.402l-3.34 4-1.097 1.359v-5.36h-1.985v11.419h1.985Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'EUR': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' y1='-2.104' x2='0' y2='100.532' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%238ba6ee%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23263aa1%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M0 18C0 8.059 8.059 0 18 0s18 8.059 18 18-8.059 18-18 18S0 27.941 0 18Zm15.785 2.852h4.621V19.02h-4.62v-1.66h4.62V15.53h-4.61c.079-1.422.47-2.504 1.169-3.246.703-.746 1.707-1.117 3.012-1.117.875 0 1.753.148 2.636.441l.364-2.66c-1.153-.27-2.188-.402-3.102-.402-2.191 0-3.934.613-5.234 1.844-1.297 1.218-2 2.933-2.114 5.14h-2.246v1.828h2.23v1.66h-2.23v1.833h2.23c.071 2.234.743 3.972 2.016 5.218 1.27 1.246 3.016 1.867 5.23 1.867 1.106 0 2.18-.125 3.22-.375l-.364-2.648c-.824.285-1.71.43-2.66.43-1.316 0-2.328-.375-3.039-1.117-.7-.747-1.078-1.872-1.129-3.375Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'GBP': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='-15.5' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%23cd2231%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%233f5da8%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18Zm-25.313 6.055c.731-.278 1.305-.684 1.72-1.223v-4.375H8.073v1.504h2.356v2.234c-.43.461-1.153.692-2.164.692-.926 0-1.653-.34-2.176-1.02-.516-.683-.774-1.644-.774-2.879v-.879c.016-1.238.254-2.175.711-2.812.465-.645 1.145-.965 2.032-.965 1.375 0 2.171.688 2.394 2.063h1.945c-.152-1.184-.601-2.086-1.351-2.715-.746-.63-1.754-.942-3.027-.942-1.477 0-2.633.489-3.457 1.457-.829.97-1.239 2.317-1.239 4.04v.894c.016 1.078.223 2.023.617 2.832.403.805.973 1.426 1.711 1.86.739.433 1.586.652 2.54.652.933 0 1.765-.141 2.495-.418Zm3.899-11.16v11.418h4.164c1.281 0 2.262-.286 2.95-.86.687-.578 1.034-1.398 1.034-2.473 0-.648-.172-1.203-.511-1.66-.332-.468-.809-.785-1.426-.949.543-.23.957-.555 1.238-.973.281-.418.422-.89.422-1.414 0-1.023-.336-1.796-1.012-2.312-.672-.52-1.656-.777-2.945-.777Zm1.984 9.835v-3.57h2.367c1.215.027 1.82.633 1.82 1.82 0 .551-.175.977-.527 1.286-.347.308-.835.464-1.457.464Zm1.946-5.027H16.57v-3.207h1.93c.68 0 1.176.125 1.496.375.324.25.488.66.488 1.223 0 .515-.175.91-.527 1.195-.344.273-.824.414-1.441.414Zm8.187 6.61V20.07h2.36c1.324 0 2.351-.308 3.082-.933.738-.621 1.105-1.492 1.105-2.61 0-1.093-.375-1.972-1.129-2.636-.746-.664-1.758-.996-3.035-.996h-4.367v11.418Zm2.383-5.833h-2.383v-3.984h2.438c.66.008 1.175.2 1.554.57.375.368.563.86.563 1.477 0 .625-.184 1.11-.555 1.441-.371.332-.91.496-1.617.496Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'HUF': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='0' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%23fc3d1d%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%236a9f3c%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18Zm-25.586 5.602h1.977V12.184h-1.977v4.754H5.31v-4.754H3.324v11.418H5.31v-5.075h5.105Zm12.68-3.786v-7.632h-1.977v7.578c0 1.61-.762 2.414-2.289 2.414-.754 0-1.324-.2-1.71-.606-.388-.402-.579-.984-.579-1.746v-7.64h-1.977v7.625c0 1.238.383 2.207 1.153 2.91.77.695 1.805 1.043 3.113 1.043 1.293 0 2.324-.356 3.098-1.059.777-.71 1.168-1.676 1.168-2.887Zm4.11-1.05H31.8V17.18h-4.598v-3.395h5.27v-1.601h-7.254v11.418h1.984Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'ILS': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='-15.5' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:white%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23083db5%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18ZM7.2 23.652h2.077V11.793H7.2Zm12.187-1.644h-5.453V11.793h-2.09v11.86h7.543Zm7.32-2.613c.371.28.559.683.559 1.203 0 .476-.184.863-.551 1.156-.363.289-.91.433-1.637.433-.84 0-1.484-.187-1.937-.554-.45-.371-.676-.89-.676-1.563h-2.09c0 .742.203 1.395.605 1.953.407.559.973 1 1.7 1.32a6.04 6.04 0 0 0 2.398.473c1.317 0 2.356-.293 3.121-.87.77-.587 1.156-1.376 1.156-2.364 0-.61-.132-1.137-.398-1.59-.262-.453-.68-.855-1.246-1.203-.566-.355-1.316-.668-2.25-.937-.938-.278-1.61-.559-2.016-.848-.406-.293-.61-.656-.61-1.09 0-.516.188-.918.563-1.207.38-.289.91-.43 1.59-.43.73 0 1.293.176 1.684.528.394.347.594.832.594 1.449h2.078c0-.684-.184-1.305-.559-1.863-.375-.567-.89-1-1.543-1.305-.656-.305-1.402-.457-2.238-.457-1.258 0-2.285.312-3.074.93-.79.62-1.18 1.406-1.18 2.363 0 1.09.547 1.976 1.64 2.656.57.352 1.34.672 2.31.961.968.285 1.636.57 2.007.856Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'JPY': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='-22.5' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:white%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23d40915%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18Zm-24.664-5.816H9.359v8c0 .62-.16 1.113-.48 1.468-.313.348-.734.524-1.27.524-.57 0-1.007-.153-1.308-.453-.297-.309-.45-.766-.45-1.375H3.876c0 1.074.324 1.91.973 2.511.648.602 1.57.903 2.761.903 1.121 0 2.02-.325 2.7-.965.683-.645 1.027-1.512 1.027-2.606Zm4.258 11.418v-4.243h2.36c1.323 0 2.35-.308 3.081-.933.738-.621 1.106-1.492 1.106-2.61 0-1.093-.375-1.972-1.13-2.636-.745-.664-1.757-.996-3.034-.996h-4.368v11.418Zm2.383-5.832h-2.383v-3.985h2.437c.66.012 1.18.2 1.555.57.375.368.562.86.562 1.477 0 .625-.183 1.11-.554 1.441-.371.332-.91.497-1.617.497Zm12.257-5.586-2.625 5.414-2.629-5.414h-2.203l3.836 7.214v4.204h1.992v-4.204l3.829-7.214Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'KRW': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='0' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%23f1433b%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23374aa9%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18ZM5.652 20.535 6.81 19.27l3.07 4.546h2.101l-4.007-5.863 3.793-4.66H9.617l-2.984 3.684-.98 1.253v-4.937H3.874v10.523h1.777Zm9.055-.773h1.977l2.05 4.054h1.899v-.093l-2.293-4.387c.59-.258 1.047-.621 1.375-1.094.332-.476.496-1.066.496-1.77 0-1.03-.32-1.82-.961-2.363-.64-.543-1.547-.816-2.727-.816h-3.59v10.523h1.774Zm1.824-1.469h-1.824v-3.527h1.875c.613.011 1.078.168 1.387.476.312.309.468.746.468 1.309 0 .539-.167.969-.503 1.281-.336.309-.805.461-1.403.461Zm14.942-5-1.57 7.922-1.93-7.922h-1.485l-1.949 7.906-1.586-7.906h-1.758l2.356 10.523h1.703l1.984-7.683 1.942 7.683h1.695l2.355-10.523Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'NOK': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='32.999' y1='0' x2='32.999' y2='80' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%233e53b4%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23fc3d1d%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18ZM9.844 23.762h1.984V12.34H9.86v8.133L4.754 12.34H2.77v11.422h1.984V15.66Zm12.86-2.465c.39-.848.581-1.828.581-2.95v-.632c-.004-1.113-.203-2.09-.598-2.926-.39-.844-.945-1.484-1.667-1.93-.72-.449-1.54-.675-2.473-.675-.93 0-1.754.226-2.477.683-.715.45-1.273 1.102-1.672 1.953-.39.852-.585 1.836-.585 2.95v.64c.003 1.094.203 2.059.593 2.895.399.836.957 1.484 1.68 1.937.727.45 1.55.676 2.477.676.937 0 1.761-.227 2.48-.676.723-.453 1.273-1.101 1.66-1.945Zm-2.126-6.453c.485.676.723 1.644.723 2.91v.594c0 1.289-.238 2.265-.715 2.933-.469.672-1.145 1.004-2.023 1.004-.868 0-1.547-.34-2.04-1.027-.484-.684-.726-1.653-.726-2.91v-.657c.008-1.23.254-2.18.734-2.847.489-.676 1.16-1.012 2.016-1.012.879 0 1.558.336 2.031 1.012Zm6.668 5.355 1.293-1.37 3.438 4.933h2.351l-4.488-6.364 4.246-5.058h-2.402l-3.34 4-1.098 1.36v-5.36h-1.984v11.422h1.984Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'NZD': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='0' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%23fc0d1b%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%232f3487%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18Zm-25.602 6.156h1.985V12.738h-1.969v8.133L5.31 12.738H3.324v11.418H5.31v-8.101Zm12.297-1.582h-6.101l5.914-8.582v-1.254H14.23v1.602h5.868L14.19 22.87v1.285h8.504Zm1.707-9.836v11.418h3.29c1.027 0 1.94-.222 2.734-.664a4.525 4.525 0 0 0 1.844-1.898c.433-.82.652-1.774.652-2.856v-.574c0-1.066-.215-2.012-.645-2.836a4.58 4.58 0 0 0-1.82-1.914c-.777-.45-1.672-.676-2.68-.676Zm1.985 9.836V14.34h1.39c1.024 0 1.805.324 2.344.98.54.653.809 1.598.809 2.836v.637c-.012 1.219-.297 2.152-.856 2.809-.554.648-1.351.972-2.39.972Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'PLN': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='32.999' y1='-32.5' x2='32.999' y2='80' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:white%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23f1433b%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18ZM5.86 23.602v-4.243h2.363c1.32 0 2.347-.308 3.082-.933.738-.621 1.105-1.492 1.105-2.61 0-1.093-.379-1.972-1.129-2.636-.75-.664-1.761-.996-3.035-.996H3.875v11.418Zm2.386-5.832H5.86v-3.985h2.442c.656.012 1.176.2 1.55.57.38.368.567.86.567 1.477 0 .625-.188 1.11-.559 1.441-.37.332-.91.497-1.613.497Zm13.074 4.25h-5.183v-9.836h-1.985v11.418h7.168Zm8.594 1.582h1.984V12.184H29.93v8.132l-5.106-8.132H22.84v11.418h1.984v-8.098Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'RUB': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='32.999' y1='-15.5' x2='32.999' y2='80' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:white%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%233e53b4%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18ZM5.86 19.758h2.214l2.29 4.398h2.124v-.101l-2.566-4.762c.66-.273 1.172-.672 1.539-1.184.371-.515.555-1.156.555-1.922 0-1.117-.356-1.972-1.075-2.562-.714-.59-1.73-.887-3.05-.887H3.875v11.418h1.984Zm2.038-1.594H5.86V14.34h2.094c.692.008 1.211.18 1.555.515.351.336.527.81.527 1.422 0 .582-.191 1.047-.566 1.387-.375.336-.899.5-1.57.5Zm14.356 2.207v-7.633h-1.977v7.575c0 1.613-.765 2.417-2.293 2.417-.75 0-1.32-.203-1.707-.605-.386-.402-.582-.984-.582-1.746v-7.64H13.72v7.624c0 1.239.386 2.207 1.152 2.907.77.695 1.809 1.043 3.113 1.043 1.293 0 2.325-.352 3.098-1.06.781-.71 1.172-1.671 1.172-2.882Zm2.125-7.633v11.418h4.164c1.281 0 2.262-.285 2.95-.863.687-.574 1.034-1.398 1.034-2.469 0-.648-.172-1.203-.511-1.664-.336-.465-.809-.781-1.426-.95.543-.226.957-.55 1.238-.972.281-.418.422-.886.422-1.41 0-1.023-.336-1.797-1.012-2.312-.672-.52-1.656-.778-2.949-.778Zm1.984 9.836v-3.57h2.367c1.211.027 1.82.633 1.82 1.82 0 .551-.175.977-.527 1.285-.351.309-.835.465-1.457.465Zm1.942-5.027h-1.942V14.34h1.926c.68 0 1.18.125 1.5.375.324.25.484.66.484 1.223 0 .511-.171.91-.523 1.19-.344.278-.828.419-1.445.419Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'SEK': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='33' y1='0' x2='32.5' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%23fdc12d%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%231d78d0%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18ZM9.89 19.66c.356.27.536.656.536 1.16 0 .461-.176.832-.528 1.114-.343.277-.859.414-1.55.414-.801 0-1.414-.176-1.844-.532-.43-.355-.645-.859-.645-1.507H3.875c0 .718.191 1.343.574 1.882.387.54.926.961 1.617 1.27a5.613 5.613 0 0 0 2.282.457c1.25 0 2.238-.281 2.964-.84.731-.566 1.098-1.324 1.098-2.273 0-.586-.125-1.098-.379-1.532-.25-.437-.644-.824-1.183-1.16-.54-.34-1.25-.64-2.141-.902-.887-.266-1.527-.54-1.914-.816-.387-.282-.578-.633-.578-1.051 0-.496.176-.883.531-1.16.363-.278.867-.414 1.516-.414.695 0 1.226.168 1.597.507.38.336.567.801.567 1.399h1.976c0-.66-.18-1.258-.535-1.797a3.485 3.485 0 0 0-1.465-1.254c-.62-.293-1.332-.441-2.125-.441-1.199 0-2.172.3-2.925.894-.747.598-1.122 1.356-1.122 2.274 0 1.05.52 1.906 1.56 2.558.546.34 1.276.649 2.198.926.918.277 1.555.55 1.903.824Zm6.247-.996h4.687v-1.57h-4.687V13.94h5.425V12.34h-7.41v11.422h7.465v-1.586h-5.48Zm9.078 1.535 1.297-1.37 3.433 4.933h2.352l-4.484-6.364 4.242-5.058h-2.399l-3.343 4-1.098 1.36v-5.36H23.23v11.422h1.985Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'TRY': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='32.002' y1='-60' x2='32.002' y2='65' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:white%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23fc3d1d%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18ZM9.375 14.34h3.559v-1.602H3.875v1.602h3.531v9.816h1.969Zm7.05 5.418h2.212l2.289 4.398h2.125v-.101l-2.563-4.762c.657-.273 1.172-.672 1.535-1.184.372-.515.559-1.156.559-1.922 0-1.117-.36-1.972-1.074-2.562-.719-.59-1.735-.887-3.051-.887h-4.016v11.418h1.985Zm2.04-1.594h-2.04V14.34h2.095c.69.008 1.207.18 1.55.515.352.336.528.81.528 1.422 0 .582-.188 1.047-.567 1.387-.375.336-.898.5-1.566.5Zm11.976-5.426-2.628 5.41-2.63-5.41H22.98l3.836 7.215v4.203h1.993v-4.203l3.828-7.215Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'USD': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' y1='-2.104' x2='0' y2='100.532' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%238beeca%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%2326a17b%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M0 18C0 8.059 8.059 0 18 0s18 8.059 18 18-8.059 18-18 18S0 27.941 0 18Zm20.652 2.387c.47.476.7 1.082.7 1.82 0 .785-.258 1.418-.778 1.895-.512.476-1.258.714-2.246.714-1.004 0-1.781-.3-2.336-.898-.547-.598-.816-1.441-.816-2.531H12.02c0 1.715.46 3.09 1.375 4.129.925 1.039 2.246 1.652 3.96 1.843v2.52h2.063v-2.531c1.602-.164 2.848-.703 3.738-1.61.903-.91 1.352-2.097 1.352-3.558 0-.778-.117-1.457-.352-2.04a4.66 4.66 0 0 0-1-1.53 6.618 6.618 0 0 0-1.593-1.196c-.633-.344-1.504-.723-2.61-1.129-1.11-.406-1.894-.832-2.363-1.285-.461-.457-.688-1.063-.688-1.816 0-.84.235-1.485.7-1.934.468-.45 1.117-.676 1.949-.676.84 0 1.5.317 1.984.95.492.62.742 1.48.742 2.57h3.141c0-1.696-.414-3.074-1.234-4.13-.82-1.062-1.985-1.702-3.493-1.921V5.188h-2.078V8.02c-1.496.171-2.68.722-3.554 1.648-.875.926-1.313 2.105-1.313 3.543 0 2.121 1.008 3.719 3.027 4.8.606.333 1.454.688 2.543 1.079 1.09.383 1.868.812 2.336 1.297Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'ZAR': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='a' gradientUnits='userSpaceOnUse' x1='32.002' y1='0' x2='32.002' y2='96' gradientTransform='scale(.55385)'%3e%3cstop offset='0' style='stop-color:%232e7d33%3bstop-opacity:1'/%3e%3cstop offset='1' style='stop-color:%23253692%3bstop-opacity:1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg%3e%3cpath style='stroke:none%3bfill-rule:evenodd%3bfill:url(%23a)' d='M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18Zm-24.176 4.574H5.723l5.914-8.582v-1.254H3.363v1.602H9.23L3.324 22.87v1.285h8.5Zm3.79-1.074h4.421l.934 2.656h2.07l-4.32-11.418h-1.782l-4.312 11.418h2.063Zm3.866-1.602h-3.312l1.656-4.738Zm6.844-.14h2.211l2.293 4.398h2.125v-.101l-2.566-4.762c.66-.273 1.172-.672 1.539-1.184.37-.515.554-1.156.554-1.922 0-1.117-.355-1.972-1.074-2.562-.715-.59-1.73-.887-3.05-.887H24.34v11.418h1.984Zm2.04-1.594h-2.04V14.34h2.094c.691.008 1.21.18 1.555.515.351.336.523.81.523 1.422 0 .582-.187 1.047-.562 1.387-.38.336-.899.5-1.57.5Zm0 0'/%3e%3c/g%3e%3c/svg%3e",
    'knowbase': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M20.056 16.132c.453 0 .82-.352.82-.786V1.163c0-.434-.367-.786-.82-.786h-7.543c-.624 0-1.194.226-1.626.597A2.493 2.493 0 0 0 9.26.376H1.717c-.452 0-.819.352-.819.786v14.184c0 .434.367.786.82.786h18.338Zm-8.35-1.571v-1.097c0-.428.362-.776.808-.776h6.724v1.872h-7.532Zm.808-12.613h6.724v9.168h-6.724a2.53 2.53 0 0 0-.808.132V2.724c0-.428.362-.776.808-.776Zm-9.978 0H9.26c.446 0 .808.348.808.775v8.525a2.529 2.529 0 0 0-.808-.132H2.536V1.948Zm0 10.74H9.26c.446 0 .808.348.808.776v1.097H2.536v-1.873Z' fill='white'/%3e%3cpath d='M14.162 5.525h2.62c.451 0 .818-.352.818-.786 0-.434-.367-.786-.819-.786h-2.62c-.451 0-.818.352-.818.786 0 .434.367.786.819.786ZM14.162 9.1h2.62c.451 0 .818-.352.818-.786 0-.434-.367-.786-.819-.786h-2.62c-.451 0-.818.352-.818.786 0 .434.367.786.819.786ZM7.612 3.953h-2.62c-.452 0-.818.352-.818.786 0 .434.366.786.818.786h2.62c.452 0 .819-.352.819-.786 0-.434-.367-.786-.819-.786ZM7.612 7.528h-2.62c-.452 0-.818.352-.818.786 0 .434.366.786.818.786h2.62c.452 0 .819-.352.819-.786 0-.434-.367-.786-.819-.786Z' fill='white'/%3e%3c/svg%3e",
    'knowledge': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M2.143 0v12.429c2.741-.015 4.784 1.48 6.428 2.852V2.571C6.751 1.046 4.706-.002 2.143 0Zm13.714 0c-2.563-.002-4.609 1.046-6.428 2.571v12.71c1.644-1.372 3.687-2.867 6.428-2.852V0ZM0 1.714v12.429l7.5 1.286c-1.425-1.075-3.223-2.155-5.357-2.143h-.429a.445.445 0 0 1-.428-.429V1.895L0 1.715Zm18 0-1.286.181V12.857a.445.445 0 0 1-.428.429h-.429c-2.134-.012-3.932 1.068-5.357 2.143l7.5-1.286V1.714Z' fill='url(%23a)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='0' y1='15' x2='19' y2='.5' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%236B69F2'/%3e%3cstop offset='1' stop-color='%239A5FD6'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'membership': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17.193.7H1.807A1.31 1.31 0 0 0 .5 2.008v8.886c0 .72.587 1.307 1.307 1.307h15.386a1.31 1.31 0 0 0 1.307-1.307V2.008c0-.74-.587-1.308-1.307-1.308ZM9.178 6.005l-1.25 1.08a.393.393 0 0 0-.133.398l.379 1.61c.075.342-.303.626-.607.437l-1.402-.853a.41.41 0 0 0-.417 0l-1.402.853c-.303.19-.682-.095-.606-.436l.379-1.61a.393.393 0 0 0-.133-.399l-1.25-1.08c-.265-.227-.133-.682.227-.7l1.649-.133a.427.427 0 0 0 .34-.247l.645-1.515c.132-.322.606-.322.739 0l.644 1.515c.057.133.19.228.341.247l1.648.132c.342.02.474.455.209.701Zm6.48 3.828h-4.566a.51.51 0 0 1-.512-.512.51.51 0 0 1 .512-.511h4.566a.51.51 0 0 1 .511.511.51.51 0 0 1-.511.512Zm0-2.88h-4.566a.51.51 0 0 1-.512-.512.51.51 0 0 1 .512-.511h4.566a.51.51 0 0 1 .511.511c.02.284-.227.512-.511.512Zm0-2.861h-4.566a.51.51 0 0 1-.512-.512.51.51 0 0 1 .512-.511h4.566a.51.51 0 0 1 .511.511.51.51 0 0 1-.511.512Z' fill='url(%23a)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='.5' y1='6.579' x2='18.5' y2='6.579' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23169DC9'/%3e%3cstop offset='1' stop-color='%2335C192'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'mobile-wallet': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.45 1a1.4 1.4 0 0 0-1.4 1.4v15.2a1.4 1.4 0 0 0 1.4 1.4h7.1a1.4 1.4 0 0 0 1.4-1.4V2.4a1.4 1.4 0 0 0-1.4-1.4h-7.1Zm1.718 1.286a.643.643 0 0 0 0 1.285h3.664a.643.643 0 1 0 0-1.285H8.168Z' fill='url(%23a)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='5.05' y1='1.563' x2='5.05' y2='19' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%2386CB60'/%3e%3cstop offset='1' stop-color='%2331A442'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'play-button': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23a)' fill='white'%3e%3cpath d='M21.52 5.988c-1.472-2.447-3.848-4.197-6.69-4.928a11.347 11.347 0 0 0-8.362 1.057C3.92 3.529 2.096 5.81 1.334 8.539c-.761 2.729-.37 5.58 1.101 8.026 1.472 2.446 3.848 4.197 6.69 4.928.95.244 1.913.365 2.87.365 1.91 0 3.794-.48 5.492-1.422 2.549-1.412 4.372-3.693 5.134-6.422.762-2.729.37-5.58-1.101-8.026Zm-.008 7.74c-.682 2.446-2.316 4.489-4.599 5.754a10.166 10.166 0 0 1-7.49.947c-2.547-.655-4.676-2.223-5.994-4.415-1.319-2.192-1.669-4.745-.986-7.19.682-2.445 2.315-4.488 4.599-5.753a10.13 10.13 0 0 1 4.92-1.274c.857 0 1.72.108 2.57.327 2.547.655 4.676 2.223 5.994 4.415 1.319 2.192 1.669 4.745.986 7.19Z'/%3e%3cpath d='M17.263 10.278 10.18 6.352a1.232 1.232 0 0 0-1.201 0c-.376.209-.6.582-.6.998v7.853c0 .417.224.79.6.998a1.236 1.236 0 0 0 1.2 0l7.085-3.926c.376-.209.6-.582.6-.998 0-.417-.224-.79-.6-.998Zm-.574 1.043-7.084 3.926c-.009.005-.027.015-.053 0-.027-.014-.027-.034-.027-.044V7.35c0-.01 0-.029.027-.044a.056.056 0 0 1 .027-.008c.012 0 .02.005.026.008l7.084 3.926a.046.046 0 0 1 .027.045c0 .03-.018.04-.027.044ZM18.75 6.252c-1.364-1.692-3.416-2.829-5.628-3.119a.57.57 0 0 0-.646.471.555.555 0 0 0 .49.62c1.918.252 3.695 1.237 4.877 2.702a.586.586 0 0 0 .805.098.536.536 0 0 0 .102-.772Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='white' transform='translate(.953 .695)' d='M0 0h22.047v21.163H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
    'search': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 9a9 9 0 1 1 18 0A9 9 0 0 1 0 9Zm9-7a7 7 0 1 0 0 14A7 7 0 0 0 9 2Z' fill='%233692FE'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.943 13.943a1 1 0 0 1 1.415 0l4.35 4.35a1 1 0 0 1-1.415 1.414l-4.35-4.35a1 1 0 0 1 0-1.414Z' fill='%233692FE'/%3e%3c/svg%3e",
    'support': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10 12.679 0 4.837v11.667h20V4.837l-10 7.842Z' fill='url(%23a)'/%3e%3cpath d='M19.785 3.516H.215L10 11.19l9.785-7.674Z' fill='url(%23b)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='10' y1='4.837' x2='10' y2='16.504' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23F76A1A'/%3e%3cstop offset='1' stop-color='%23FFB862'/%3e%3c/linearGradient%3e%3clinearGradient id='b' x1='10' y1='3.516' x2='10' y2='11.19' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23F76A1A'/%3e%3cstop offset='1' stop-color='%23FFB862'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'tg': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.408 11.71c-.198.182-.362.332-.72.332L6.94 8.5l6.823-5.814c.3-.25-.066-.373-.463-.146l-8.42 5.017-3.637-1.072c-.786-.227-.791-.737.176-1.103L15.591.22c.647-.278 1.272.147 1.025 1.082l-2.414 10.741c-.168.763-.657.946-1.334.593l-3.676-2.565-1.767 1.623-.017.016Z' fill='url(%23a)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='.671' y1='12.81' x2='17.96' y2='10.701' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%231E7AE6'/%3e%3cstop offset='1' stop-color='%232FA5F1'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'tw': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m8.104 3.645.035.54-.578-.066c-2.104-.253-3.942-1.113-5.503-2.557L1.295.846l-.196.529c-.417 1.18-.15 2.425.716 3.262.463.463.359.53-.439.254-.277-.088-.52-.155-.543-.121-.081.077.196 1.08.416 1.476.3.552.913 1.092 1.584 1.411l.566.254-.67.01c-.648 0-.67.012-.602.243.232.717 1.145 1.477 2.162 1.808l.717.232-.624.352a6.8 6.8 0 0 1-3.099.816c-.52.01-.948.055-.948.088 0 .11 1.41.727 2.232.97 2.462.716 5.387.408 7.584-.816 1.56-.87 3.121-2.601 3.85-4.276.392-.893.786-2.524.786-3.307 0-.507.034-.573.682-1.18.381-.352.74-.738.809-.848.116-.21.104-.21-.485-.022-.983.33-1.122.287-.636-.21.358-.352.636-1.113.636-1.3 0 0-.024.143-.22.242-.208.11-.67.276-1.018.375l-.624.188-.566-.364c-.313-.199-.752-.419-.983-.485-.59-.154-1.492-.132-2.023.044C8.914.967 8 2.246 8.104 3.645Z' fill='url(%23a)'/%3e%3cdefs%3e%3clinearGradient id='a' x1='.335' y1='12.81' x2='17.617' y2='10.671' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%231E7AE6'/%3e%3cstop offset='1' stop-color='%232FA5F1'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e",
    'web3-wallet': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5 5.5h1.636c.75 0 1.364.614 1.364 1.364V8.5c1.664 0 3 1.336 3 3a3 3 0 0 1-3 3v1.636c0 .75-.614 1.364-1.364 1.364H3.864c-.75 0-1.364-.614-1.364-1.364v-1.554c0-.341.286-.628.627-.628a2.497 2.497 0 0 0 0-4.99.634.634 0 0 1-.627-.628V6.864c0-.75.614-1.364 1.364-1.364H5.5a3 3 0 0 1 3-3c1.664 0 3 1.336 3 3Z' fill='url(%23b)'/%3e%3c/g%3e%3cdefs%3e%3clinearGradient id='b' x1='10' y1='2.5' x2='10' y2='17.5' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23F76A1A'/%3e%3cstop offset='1' stop-color='%23FFB862'/%3e%3c/linearGradient%3e%3cclipPath id='a'%3e%3cpath fill='white' transform='translate(2.5 2.5)' d='M0 0h15v15H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e"
);

$sizes: (
    'academy': (
        'width': 20px,
        'height': 17px
    ),
    'airdrop': (
        'width': 21px,
        'height': 20px
    ),
    'arrow': (
        'width': 14px,
        'height': 9px
    ),
    'assets-status': (
        'width': 16px,
        'height': 20px
    ),
    'assets': (
        'width': 20px,
        'height': 14px
    ),
    'blockchain': (
        'width': 26px,
        'height': 24px
    ),
    'blog': (
        'width': 17px,
        'height': 17px
    ),
    'book-outline': (
        'width': 22px,
        'height': 20px
    ),
    'dapps': (
        'width': 20px,
        'height': 20px
    ),
    'desktop-wallet': (
        'width': 20px,
        'height': 20px
    ),
    'download': (
        'width': 20px,
        'height': 18px
    ),
    'explorer': (
        'width': 17px,
        'height': 18px
    ),
    'fb': (
        'width': 9px,
        'height': 17px
    ),
    'AUD': (
        'width': 36px,
        'height': 36px
    ),
    'CAD': (
        'width': 36px,
        'height': 36px
    ),
    'CHF': (
        'width': 36px,
        'height': 36px
    ),
    'CZK': (
        'width': 36px,
        'height': 36px
    ),
    'DKK': (
        'width': 36px,
        'height': 36px
    ),
    'EUR': (
        'width': 36px,
        'height': 36px
    ),
    'GBP': (
        'width': 36px,
        'height': 36px
    ),
    'HUF': (
        'width': 36px,
        'height': 36px
    ),
    'ILS': (
        'width': 36px,
        'height': 36px
    ),
    'JPY': (
        'width': 36px,
        'height': 36px
    ),
    'KRW': (
        'width': 36px,
        'height': 36px
    ),
    'NOK': (
        'width': 36px,
        'height': 36px
    ),
    'NZD': (
        'width': 36px,
        'height': 36px
    ),
    'PLN': (
        'width': 36px,
        'height': 36px
    ),
    'RUB': (
        'width': 36px,
        'height': 36px
    ),
    'SEK': (
        'width': 36px,
        'height': 36px
    ),
    'TRY': (
        'width': 36px,
        'height': 36px
    ),
    'USD': (
        'width': 36px,
        'height': 36px
    ),
    'ZAR': (
        'width': 36px,
        'height': 36px
    ),
    'knowbase': (
        'width': 21px,
        'height': 17px
    ),
    'knowledge': (
        'width': 18px,
        'height': 16px
    ),
    'membership': (
        'width': 19px,
        'height': 13px
    ),
    'mobile-wallet': (
        'width': 20px,
        'height': 20px
    ),
    'play-button': (
        'width': 23px,
        'height': 22px
    ),
    'search': (
        'width': 20px,
        'height': 20px
    ),
    'support': (
        'width': 20px,
        'height': 20px
    ),
    'tg': (
        'width': 17px,
        'height': 13px
    ),
    'tw': (
        'width': 17px,
        'height': 13px
    ),
    'web3-wallet': (
        'width': 20px,
        'height': 20px
    )
);

$variables: (
    /* EMPTY */
);

// https://github.com/waldemarfm/sass-svg-uri/blob/v1.0.0/_svg-uri.scss
@function sprite-str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if type-of($replace) == 'null' {
        $replace: '';
    }

    @if ( $index ) {
        @return str-slice($string, 1, $index - 1) + $replace + sprite-str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function sprite-svg-uri($value) {
    $output: $value;

    $output: sprite-str-replace($output, '"', "'");
    $output: sprite-str-replace($output, '<', '%3C');
    $output: sprite-str-replace($output, '>', '%3E');
    $output: sprite-str-replace($output, '&', '%26');
    $output: sprite-str-replace($output, '#', '%23');

    @return $output;
}

@mixin sprite($name, $user-variables: (), $include-size: false, $property: 'background') {
    $sprite: map-get($sprites, $name);

    @if $sprite == null {
        @error 'Sprite \'#{$name}\' does not exists';
    }

    // Inject variables
    $default-variables: map-get($variables, $name);
    @if type-of($default-variables) == 'map' {
        @each $key, $value in map-merge($default-variables, $user-variables) {
            @if ( not map-has-key($default-variables, $key) ) {
                @warn 'Sprite \'#{$name}\' does not support variable named \'#{$key}\'';
            }

            $sprite: sprite-str-replace($sprite, '___#{$key}___', sprite-svg-uri(quote(#{$value})));
        }
    } @else if type-of($user-variables) == 'map' {
        @warn 'Sprite \'#{$name}\' does not contain any variables';
    }

    #{$property}: url($sprite) center no-repeat;

    @if $include-size {
        $size: map-get($sizes, $name);
        $width: map-get($size, width);
        $height: map-get($size, height);

        @if $include-size == true {
            #{$property}-size: $width $height;
        } @else if $include-size == 'box' {
            width: $width;
            height: $height;
        }
    }
}

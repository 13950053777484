.img-hidden {
    display: none;
}
.modal-overlay{
    opacity: 0;
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background .2s ease-in;

    &.open{
        opacity: 1;
        z-index: 1050;
        background: rgba($primary, .8);
        .modal-window{
            transform: translateY(0);
        }
    }
}
.modal-window{
    width: 100%;
    max-width: 680px;
    background: #E9F5FE;
    border-radius: 4px;
    padding: 60px 50px;
    position: relative;
    transform: translateY(-100px);
    transition: transform .2s ease-in;
    text-align: center;
    // ------------media-------------
    @include w($sm){
        padding-left: 15px;
        padding-right: 15px;
    }
    &__link {
        color: $blue;
    }
    &__close{
        position: absolute;
        right: 5px;
        top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42.18px;
        height: 42.18px;
        cursor: pointer;

        span {
            position: absolute;
            background-color: $primary;
            width: 21.09px;
            display: block;
            height: 1px;
            transition: .2s ease-in-out;
        }
        span:first-child {
            transform: rotate(45deg);
        }
        span:last-child {
            transform: rotate(-45deg);
        }

        &:hover{
            .modal-window__close span {
                background-color: $blue;
                height: 3px;
            }
        }
    }
    &__close--dark-mode {
        span {
            background-color: #ffffff;
        }
    }
    .title-3,.p-18{
        padding-bottom: 12px;
    }
    .social-links{
        margin: 32px auto;
        // ------------media-------------
        @include w($sm){
            margin: 16px auto;
            padding: 16px 0;
        }
    }
    .two-buttons-container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -8px;
        margin-right: -8px;
        &>*{
            margin: 0 8px;
        }
        // ------------media-------------
        @include w($sm){
            flex-direction: column;
            &>*{
                margin-bottom: 16px;
            }
        }
    }
}
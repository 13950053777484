.prices-section{
    padding-top: 40px;
    background: linear-gradient(180deg,#eaf6ff,#eaf6ff);

    .price{
        background: #FFFFFF;
        box-shadow: 0px 24px 36px rgba(23, 42, 63, 0.08);
        margin-bottom: -40px;
        position: relative;
        z-index: 1;
        border-radius: 10px;
    }
}


.price__body {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding-top: 10px;
    min-height: 900px;

    @include w($lg) {
        min-height: 600px;
    }
}

.price__item {
    display: flex;
    height: 90px;
    align-items: center;
    justify-content: space-between;
    padding: 0 2vw;
    // border-bottom: 1px solid #e4e7ec;
    transition: .1s ease-in-out;
    // ------------media-------------
    @include w($lg){
        height: 60px;
        p.price__name,
        p.price__number,
        p.price__apy.price__apy-td,
        p.price__cap,
        .price__change,
        p.price__sum,
        p.price__volume,
        .price--default,
        .price__button-wrap .btn-medium
        {
          font-size: 17px;
        //   line-height: 15px;
        }

        p.price__short {
          font-size: 20px;
        }
    }
    // ------------media-------------
    @include w($md){
        padding: 0 5vw;
    }
    @include w($sm) {
        border-bottom: unset;

        p.price__name,
        p.price__number,
        p.price__apy.price__apy-td,
        p.price__cap,
        .price__change,
        p.price__sum,
        p.price__volume
        {
          font-size: 18px;
        }
        
        p.price__short {
            font-size: 16px;
            line-height: 20px;
          }
    }
}

.price__item:hover {
    background-color: #fafafa;
}

.price__item:first-child:hover {
    background-color: transparent
}

.price__number {
    width: 3%;
    font-size: 18px;
    color: #135;
    margin-bottom: unset;
    // ------------media-------------
    @include w($md){
        display: none;
    }
}

.price__info {
    display: flex;
    align-items: center;
    width: 26%;
    // ------------media-------------
    @include w($lg){
        .icon{
            font-size: 2.5em;
        }
    }
}

.price__row {
    display: flex;
    line-height: 30px;
    // ------------media-------------
    @include w($md){
        line-height: 1.4;
    }
}

.price__info .price__row {
    flex-wrap: wrap;

    // ------------media-------------
    @include w($sm){
        flex-direction: column;
        line-height: 1;
    }
}

.price__row--right {
    width: calc(70% - 130px);
    align-self: stretch;
    justify-content: space-between;
    padding-right: 45px;
    // ------------media-------------
    @include w($md){
        flex-direction: column;
        width: auto;
        justify-content: center;
        padding-right: 0;
    }
}

.price__name {
    margin-left: 10px;
    font-size: 20px;
    color: #135;
    text-transform: capitalize;
    white-space: nowrap;
}

.price__short {
    margin-left: 10px;
    font-size: 20px;
    color: #b1bdcd;
    text-transform: uppercase
}

.price__cap {
    display: flex;
    justify-content: flex-start;
    font-size: 22px;
    color: #135;
    width: 28%;
    // ------------media-------------
    @include w($md){
        display: none;
    }
}

.price__sum {
    display: flex;
    justify-content: flex-start;
    font-size: 22px;
    color: #135;
    width: 25%;
    // ------------media-------------
    @include w($sm){
        display: none;
    }
}

.price__change {
    display: flex;
    justify-content: flex-start;
    font-size: 22px;
    color: #ff5c5c;
    width: 23%;
    // ------------media-------------
    @include w($md){
        display: none;
    }
}

.price__cap,.price__change,.price__name,.price__short,.price__sum,.price__volume {
    justify-content: center;
    align-items: center;
    margin-bottom: unset
}

.price--green .price__change {
    color: #41c47a
}

.price__button-wrap {
    display: flex;
    justify-content: flex-start;
    width: 130px;

    .btn-medium{
        font-size: 18px;
        padding: 0 10px;
        width: 100%;
        text-transform: capitalize;
        height: 50px;
        // ------------media-------------
        @include w($lg){
            height: 45px;
        }
    }
    // ------------media-------------
    @include w($md){
        display: none;
    }
}

.price--default {
    font-size: 20px;
    color: #637b94;
    margin-bottom: unset;
    white-space: nowrap;
    &.price__volume{
        font-size: 20px;
        color: #637b94;
    }
}

.price__footer{
    padding: 20px 0;
    text-align: center;
}

.price__apy{
    width: 12%;
    align-items: center;
    display: flex;
    justify-content: right;
}
.price__apy-td{
    font-size: 22px;
    line-height: 1;
    color: #113355;
    // background: #E9F5FE;
    // ------------media-------------
    @include w($md){
        background: transparent;
    }
}

.price__volume {
    display: flex;
    justify-content: flex-start;
    font-size: 22px;
    color: #135;
    width: 20%;
    // ------------media-------------
    @include w($md){
        display: none;
    }
}

// ------------media-------------
@include w($md){
    .price__sum,
    .price__apy,
    .price__info{
        width: auto;
    }
    .price__sum,
    .price__apy{
        justify-content: flex-end;
    }
}
// ------------media-------------
@include w(360px){
    .price__name{
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 130px;
    }
}
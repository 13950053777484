$offset: 40px;
$offset-sm: 10px;
.supported-section {
	padding: 100px 0;
}
.supported-coins{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: -$offset;
    margin-right: -$offset;
	flex-wrap: wrap;
	// ------------media-------------
	@include w($md){
		margin-left: -30px;
		margin-right: -30px;
	}
	// ------------media-------------
	@include w($sm){
		margin-left: -$offset-sm;
		margin-right: -$offset-sm;
	}
}
.coin-item{
    text-align: center;
    width: calc((100% / 7) - #{($offset*2)});
    margin: $offset;
	margin-right: 10px;

    &__name{
        margin-top: 20px;
	}
	// ------------media-------------
	@include w($md){
		width: calc((100% / 5) - #{($offset*2)});
	}
	// ------------media-------------
	@include w($sm){
		margin: $offset-sm;
		width: calc((100% / 3) - #{($offset-sm*2)});
		&__icon.icon{
			display: block;
			font-size: 3em;
		}
		&__name{
			margin-top: 10px;
		}
	}
}
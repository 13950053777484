$breakpoint-1:1700px;
$breakpoint-2:1500px;

.first-section-style {
	position: relative;
	overflow: hidden;
	.container{
		padding-top: 100px;
		padding-bottom: 220px;
		// ------------media-------------
		@include w($breakpoint-1){
			padding-bottom: 160px;
		}
		// ------------media-------------
		@include w($breakpoint-2){
			padding-top: 30px;
			padding-bottom: 110px;
			max-width: 1110px;
		}
		// ------------media-------------
		@include w($lg){
			padding-bottom: 95px;
		}
		@include w($sm){
			padding-top: 0px;
		}
	}
}
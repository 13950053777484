.header {
	.container{
		max-width: 1330px;
	}
	// ------------media-------------
	@include w($lg){
		.container{
			max-width: 1220px;
		}
	}
}
.header {
	// ------------media-------------
	@include w($sm){
		.container{
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	@include w($xs) {
		.container{
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}
.for-tablet{
	display: none;
	// ------------media-------------
	// @include w($sm){
	// 	display: none !important;
	// }
	@include w($md){
		height: 70%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-bottom: 50px;

		&__bottom {
			padding: 0 25px;
		}
	}
}

.header{
	$header-color: #113355;
	// background: $primary;
	background: $header-color;
	
    color: #fff;
	z-index: 100;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
    .logo{
        width: 104px;

		@include w($xs) {
			width: 80px;
		}
	}
	.social-links-2{
		margin-bottom: 0!important;
	}
	&.open{
		// ------------media-------------
		@include w($md){
			.burger__item:nth-child(1) {
				transform: rotate(45deg);
			}
			.burger__item:nth-child(2) {
				opacity: 0;
			}
			.burger__item:nth-child(3) {
				transform: rotate(-45deg);
			}
	
			.header-col-menu{
				display: flex;
				position: absolute;
				top: calc(100% - 1px);
				left: 0;
				flex-direction: column;
				right: 0;
				width: 100%;
				height: calc(100vh - 80px);
				z-index: 100;
				background: $header-color;
				padding-top: 20px;
				// padding: 10px 20px;
				overflow-y: auto;
				overflow-x: hidden;
				.social-links-2{
					justify-content: center;
					font-size: 38px;
					padding: 20px 0;
					li{
						margin: 0 10px;
						font-size: 38px;
					}
					a{
						background: #fff;
						border-radius: 50%;
						img{
							filter: invert(.8);
							width: 20px;
							max-height: 20px;
						}
					}
				}
				@include w($md) {
					height: calc(100vh - 50px);
				}
				@include w($xs) {
					height: calc(100vh - 15px);
				}
			}
		}
	}

	// ------------media-------------
	@include w($md){
		.icon-xlm{
			background: linear-gradient(to bottom, #52b3ff 0%, #2989d8 100%);
		}
	}
	.for-tablet{
		.social-links-2 li{
			&:nth-child(2){
				a{
					img{
						margin-right: 3px;
					}
				}	
			}
		}
	}
}
.dropdown__body .dropdown__list:nth-child(even) p {
	display: block;
}
.membership-link{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	// ------------media-------------
	@include minw($xs){
		margin-left: 24px;
	}
	img{
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 10px rgba(250, 255, 0, 0.7)) drop-shadow(0px 0px 4px #FF5C00);
	}
}
.header-row{
    display: flex;
    align-items: center;
	height: 80px;
	// ------------media-------------
	@include w($xs){
		height: 50px;
		justify-content: space-between;
	}
}
.header-col{
	
	&-logo{
		margin-right: 80px;
		// ------------media-------------
		@include w($lg){
			margin-right: 40px;
		}
	}
	&-menu{
		display: flex;
		height: 100%;
	}
	&-lang{
		display: flex;
		height: 100%;
		margin-left: auto;
		&+.header-col-social{
			// margin-right: auto;
			margin-left: 20px;
		}
	}
	&-social{
		margin-left: auto;
		.social-links-2{
			a{
				font-size: 40px;
				&:hover{
					filter:invert(50%) sepia(51%) saturate(2878%) hue-rotate(175deg) brightness(100%) contrast(100%)
				}
			}
		}
	}
	&-download{
		margin-left: 32px;
		.btn-big{
			padding: 0 45px;

		}
	}
	// ------------media-------------
	@include w($md){
		&-logo{
			flex-grow: 1;
		}
		&-social{
			margin-right: 30px;
		}
		&-download{
			margin-left: 0;
			margin-right: 30px;
		}
		&-menu{
			display: none;
		}
		&-lang{
			display: none;
		}
	}
	// ------------media-------------
	@include w($sm){
		&-social{
			display: none;
		}
		&-download{
			margin-left: 30px;
			margin-right: 0;
			.btn-big{
				padding: 0 15px;
				font-size: 12px;
			}
		}
		&-logo{
			margin-right: 0;
		}
	}
	// ------------media-------------
	@include w($xs){
		&-download{
			margin: 0 5px;
			.btn-big {
				height: 35px;
			}
		}
	}
}

.nav-content{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}
.nav-content ul{
    list-style: none;
}

.nav-menu{
    display: flex;
    align-items: center;
    li{
        margin-right: 2rem;
    }
}
.dropdown{
	position: relative;
	display: inline-flex;
	align-items: center;
	margin-right: 30px;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 14px;
	// ------------media-------------
	@include w($md){
		align-items: flex-start;
		flex-direction: column;
		margin-right: 0;
		font-size: 18px;
	}
	// ------------media-------------
	@include w($sm){
		font-size: 16px;
		display: block;
	}
    &__value{
		white-space: nowrap;
		width: 100%;
		font-weight: bold;
		font-size: 15px;
		transition: 0.2s ease-in-out;
		&::after{
			margin-left: 5px;
			margin-bottom: 2px;
			display: inline-block;
			content: "";
			background-color: inherit;
			background-image: url("/src/images/arrow-down-blue.svg");
			background-repeat:no-repeat;
			background-position: center;
			background-size: contain;
			filter: grayscale(1);
			width: 13px;
			height: 7px;
		}
		// ------------media-------------
		@include w($md){
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			min-height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 20px;
			min-height: 60px;
		}
    }
	&__body {
		background: #FFFFFF;
		box-shadow: 0px 10px 15px  rgba(0, 0, 0, 0.1), 0px 4px 6px  rgba(0, 0, 0, 0.05);
		border-radius: 16px;
		gap: 50px;
		padding: 25px 0;
		justify-content: space-between;
		display: none;
        position: absolute;
        top: 100%;
        left: -20px; 
		min-width: 190px;

		&::before {
			content: '';
			width: 20px;
			height: 20px;
			background: #FFFFFF;
			transform: rotate(45deg);
			position: absolute;
			top: -7px;
        	left: 35px;
			border-radius: 2px;
		}

		@include w($md){
			width: 100%;
			border-radius: 0;
			position: relative;
			top: auto;
			left: auto;
			box-shadow: none;
			background: transparent;
			border: 0;
			padding: 0;
			&::before {
				content: none;
			}
		}
	}
	&__list {
		display: flex;
		flex-direction: column;
	}
	&__list-title {
		font-weight: 500;
		font-size: 22px;
		color: #1F1F1F;
		text-transform: none;
		white-space: nowrap;
		padding: 0 25px;
		

		@include w($md){
			text-transform: uppercase;
			color: #637B94;
			padding: 15px 25px 25px;
			font-size: 16px;
			order: -1
		}
		@include w($sm) {
			display: none;
		}
	}
    &__list-items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
    	grid-template-rows: repeat(11, auto);
		grid-auto-flow: column;
		white-space: nowrap;
        padding: 0 0 15px;
		max-height: 515px;
		z-index: 1;
		// ------------media-------------
		@include w($md) {
			display: block;
			max-height: none;
			padding: 0;
			border-bottom: $border;
			li:not(:first-child){
				border-top: $border;
			}
		}
	}
	&__list-link {
		color: #2292FA;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		padding: 0 25px;
		position: relative;
		text-transform: none;
		width: max-content;

		&::after {
			content: url(/src/images/arrow-right-small.svg);
			position: absolute;
			top: 10%;
			height: 10px;
			right: 5px;
		}
		@include w($md) {
			order: -1;
			padding-bottom: 10px;
			border-bottom: $border;
			width: 100%;
			&:after {
				right: auto;
				transform: translate(5px, -1px);
			}
		}
		@include w($sm) {
			order: -1;
			padding-bottom: 10px;
			padding-top: 10px;
			border-bottom: $border;
			width: 100%;
			&:after {
				right: auto;
				transform: translate(5px,7px);
			}
		}
	}
	&.open{
		// ------------media-------------
		@include w($md){
			.dropdown__body{
				display: block;
				background: #0B2846;
			}
			.dropdown__value {
				color: #2292FA;
				&::after{
					transform: scaleY(-1);
					filter: grayscale(0);
				}
			}
			.dropdown__value--link {
				&::after {
					transform: scaleY(1);
				}
			}
		}
	}
	// ------------off hover-------------
	@include offHoverOnDevices{
		&:hover{
			.dropdown__body{
				display: flex;
			}
			.dropdown__value {
				color: #2292FA;
				&::after{
					transform: scaleY(-1);
					filter: grayscale(0);
				}
			}
			.dropdown__value--link {
				&::after {
					transform: scaleY(1);
				}
			}
		}
	}
	&__value--link {
		&::after {
			background-image: url("/src/images/out-arrow.svg");
			height: 12px;
			width: 10px;
    		margin-bottom: 0;
		}
	}
}
.dropdown-item{
	padding: 8px 25px;
	color: $parag;
	font-size: 16px;
	display: flex;
	text-transform: none;
	gap: 10px;
	align-items: center;
	transition: $t1 ease-in-out;
	position: relative;
	height: 2.4em;

	@include w($md) {
		height: 2.6em;
	}
	// ------------off hover-------------
	@include offHoverOnDevices{
		&:hover{
			color: #2292FA;
		}
	}
	&__icon{
		font-size: 20px!important;
		min-width: 22px;
		margin-right: 5px;
		
		&.sprite-assets-status {
			margin-left: 3px;
		}
	}
	&__name{
		// ------------media-------------
		@include w($md){
			color: #fff;
		}
	}
	&__badge{
		font-weight: 500;
		font-size: 7px;
		line-height: initial;
		padding: 4px 7px;
		text-align: center;
		letter-spacing: 0.09em;
		text-transform: uppercase;
		background: linear-gradient(180deg, #E82323 0%, #CA1313 99.99%, rgba(234, 25, 25, 0) 100%);
		border-radius: 30px;
		color: #fff;
	}
}
.lang-dropdown{
	margin-right: 0;
	.dropdown-item__icon{
		flex-shrink: 0;
		img{
			max-width: none;
		}
	}
	// ------------media-------------
	@include w($md){
		width: 100%;
	}
}
.burger{
	display: none;
	color: #fff;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 25px;
	width: 30px;
	position: relative;
	margin-left: auto;
	// ------------media-------------
	@include w($md){
		display: flex;
	}
	// ------------media-------------
	@include w($sm){
		margin-left: 20px;
	}
	// ------------media-------------
	@include w($xs){
		width: 20px;
		margin-left: 0;
	}
	&__item{
		position: absolute;
		width: 30px;
		background-color: #fff;
		display: block;
		height: 3px;
		transition: .3s ease-in-out;
		&:nth-child(1) {
			transform: translateY(11px);
		}
		&:nth-child(3) {
			transform: translateY(-11px);
		}

		@include w($xs) {
			width: 20px;
			height: 2px;

			&:nth-child(1) {
				transform: translateY(6px);
			}
			&:nth-child(3) {
				transform: translateY(-6px);
			}
		}
	}
}
.exchange-section {
	padding: 100px 0;
	&__text {
		[class^="title-"] {
			text-align: left;
		}
		.title-2 {
			padding-top: 100px;
		}
		.title-4 {
			color: $parag;
			padding-top: 20px;
			padding-bottom: 40px;
			line-height: 1.6;
		}
		// ------------media-------------
		@include w($md){
			.title-2 {
				padding-top: 0;
			}
		}
		// ------------media-------------
		@include w($sm){
			text-align: center;

			.title-2,
			.title-4{
				text-align: center;
			}
			padding-top: 16px;
		}

		@include w($xs) {
			.title-4{
				padding-top: 16px;
			}
			padding-top: 32px;
		}
	}
	img {
		margin-left: auto;
		// ------------media-------------
		@include w($sm){
			margin-bottom: 40px;
			margin-left: 0;
			margin: 0 auto;
		}
	}
	.row:nth-child(2) {
		img {
			margin-left: 0;
		}
	}
	.row + .row {
		margin-top: 50px;
		// ------------media-------------
		@include w($sm){
			margin-top: 80px;
		}
	}
	// ------------media-------------
	@include w($sm){
		.row:nth-child(1),
		.row:nth-child(3){
			flex-direction: column-reverse;
		}
	}
}
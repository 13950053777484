.offer-section{
    position: relative;
    background: linear-gradient(271.26deg, #B6EBFD -1.01%, rgba(180, 234, 253, 0) 40.26%), linear-gradient(88.97deg, #DBDFFE 7.7%, rgba(219, 223, 254, 0) 69.69%), #EAF6FF;
    overflow: hidden;

    &--hot {
        position: relative;
        overflow: visible;

        &::before {
            content: url('/src/images/prices/fire.svg');
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%,-50%);
            height: fit-content;
        }
    }
    &--new {
        position: relative;
        overflow: hidden;

        &::before {
            content: url('/src/images/new-badge.svg');
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;

            @include w(435px) {
                transform: translate(-20px, -20px) scale(0.6);
            }
        }
    }
    .rounds-1{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        // ------------media-------------
        @include w($sm){
            left: -50%;
        }
    }
    .rounds-1:before,
    .rounds-1:after{
        content: '';
        mix-blend-mode: multiply;
        width: 354px;
        height: 354px;
        border-radius: 50%;
        position: absolute;
    }
    .rounds-1:before{
        left: -77px;
        top: -47px;
        background: #CACFFD;
    }
    .rounds-1:after{
        left: 88px;
        bottom: 24px;
        background: #CED5FD;
        // ------------media-------------
        @include w($sm){
            left: 88px;
            bottom: 50%;
        }
    }
    .rounds-2{
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        // ------------media-------------
        @include w($sm){
            right: -50%;
        }
    }
    .rounds-2:before,
    .rounds-2:after{
        content: '';
        mix-blend-mode: multiply;
        border-radius: 50%;
        position: absolute;
    }
    .rounds-2:before{
        right: 93px;
        top: 10px;
        background: #AEE7FC;
        width: 211px;
        height: 211px;
        // ------------media-------------
        @include w($sm){
            top: auto;
            bottom: -40%;
        }
    }
    .rounds-2:after{
        right: -57px;
        bottom: -19px;
        background: #9EE4FC;
        width: 301px;
        height: 301px;
    }
}
.offer-link{
    // position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    overflow: hidden;
    flex-wrap: wrap;
    z-index: 2;
    // ------------media-------------
    @include w(1400px){
        padding: 5px 0;
    }
    &__img {
        width: 66px;
    }
    &__icons{
        // max-width: 60px;
        // max-height: 60px;
        display: flex;
        .icon{
            font-size: 58px;
            margin-right: 20px;
            // ------------media-------------
            @include w($md){
                font-size: 45px;
            }
            @include w($sm) {
                margin-right: 0;
            }
        }
    }
    &__text, .first-section &__text {
        font-weight: 500;
        font-size: 22px;
        line-height: 40px;
        color: $dark-blue;
        margin: 10px 50px;
        text-align: center;

        mark{
            background: none;
            color: $orange;
        }

        .mark-bg-light {
            display: block;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                width: 500px;
                height: 100%;
                top: 0;
                left: -35%;
                background: rgba(255, 255, 255, 0.5);
                z-index: -1;
            }
        }
        
        .mark-red {
            color: #FF416E;
        }
        .mark-blue {
            color: #006AFF;
        }

        &--fw {
            font-weight: 400;
            mark{
                font-weight: 500;
            }
        }

        &--white {
            color: #ffffff;
        }

        // ------------media-------------
        @include w($md){
            margin: 10px 35px;
        }
    }
    // ------------media-------------
    @include w($sm){
        flex-direction: column;
        padding: 20px 0;
    }
}
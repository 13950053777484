.social-links-2{
	display: flex;
    align-items: center;
	gap: 20px;

	div {
		display: flex;
		align-items: center;

		img {
			font-size: 0;
		}
	}
}
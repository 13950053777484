.mobile-platforms{
	display: flex;
	align-items: center;
	padding-top: 30px;
	margin-left: -12px;
	margin-right: -12px;
	flex-wrap: wrap;
	justify-content: center;
	// ------------media-------------
	@include w($sm){
		.mobile-platforms{
			margin-left: -3px;
			margin-right: -3px;
			padding-top: 20px;
		}
	}
}
.mobile-item{
	margin: 12px;
	@extend .download-hover-item;
	&__logo{
		font-size: 10px;
		width: 16.5em;
		height: 5.6em;
		border: 2px solid #fff;
		border-radius: 10px;
		overflow: hidden;
		img{
			width: 100%;
		}
	}
	// ------------media-------------
	@include w($sm){
		margin: 3px;
		width: 130px;
		&__logo{
			width: 100%;
			height: auto;
		}
	}
}
.subscribe-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 760px;
    margin: 0 auto;
    // ------------media-------------
    @include w($md){
        flex-direction: column;
        max-width: 630px;
    }
}

.subscribe-email {
    background: #fff;
    box-shadow: 0 24px 36px rgba(23,42,63,.08);
    border-radius: 8px;
    color: $blue;
    border: 2px solid transparent;
    padding-left: 10px;
    padding-right: 10px;
    &::placeholder{
        color: #808080;
    }
}
.subscribe-control{
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    height: 60px;
    width: 100%;
    margin: 0 10px;
    transition: $t1 linear;
    // ------------media-------------
    @include w($md){
        margin: 0px;
        &+.subscribe-control{
            margin-top: 20px;
        }
    }
    // ------------media-------------
    @include w($sm){
        font-size: 20px;
    }
}
.subscribe-email:focus,.subscribe-email:hover {
    border-color: $blue
}

.subscribe-button {
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
}

.subscribe-button:hover {
    box-shadow: 0 24px 36px rgba(23,42,63,.08);
    background: #1d72da
}
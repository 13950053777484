@import "/src/_includes/components/mobile-platforms/styles/index.scss";

.footer{
    background: $primary;
    padding: 85px 0;
    color: #fff;
    &__left{
        .mobile-item__logo{
            font-size: 8.2px;
        }
        .mobile-platforms{
            justify-content: flex-start;
        }
        .mobile-item{
            &:hover{
                filter: brightness(1.4);
                transform: scale(1);
            }
        }
        &-support{
            position: relative;
            margin-bottom: 20px;

            .sf-root {
                width: 125px;
                position: absolute;
                right: 0;
                bottom: 100%;
                height: auto;
                transform: translateY(75%);

                @include w($md) {
                    bottom: 0;
                    right: initial;
                    left: 0;
                    transform: none;
                }
                @include w(1000px) {
                    left: initial;
                    right: 0;
                    bottom: 100%;
                    transform: translateY(70%);
                }
                @include w($sm) {
                    bottom: 0;
                    right: initial;
                    left: 50%;
                    transform: translate(-50%, 0);
                }
            }
            .footer-copy{
                display: none;
            }
            // ------------media-------------
            @include w($md){
                display: flex;
                align-items: center;

                .footer__left-copy{
                    border-left: 1px solid rgba(99, 123, 148, 0.8);
                    padding-left: 20px;
                    .mail-link{
                        margin: 0;
                        &:hover{
                            &::before {
                                content: none;
                            }
                        }
                    }
                }
                .logo{
                    margin-right: 20px;
                }
                .footer-copy{
                    display: block;
                    font-size: 14px;
                    line-height: 20px;
                    color: #B1BDCD;
                    // ------------media-------------
                    @include w($md){
                        font-size: 10px;
                    }
                }
            }
            @include w(1000px) {
                padding-bottom: 30px;
            }
            @include w($sm) {

            }
        }
    }

    // ------------media-------------
    @include w($md) {
        &__right {
            flex: 0 0 58.333333%;
            max-width: 58.333333%;
        }
        &__left{
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
    }
    @include w(1000px) {
        &__right {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &__left{
            padding-bottom: 60px;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    // ------------media-------------
    @include w($sm){
        padding: 40px 0 100px;
        &__left{
            padding-bottom: 40px;
            .mobile-platforms{
                justify-content: center;
            }
        }
        // &__right{
        //     display: none;
        // }
    }
    
}
.footer-row{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    gap: 15px;
    // ------------media-------------
    @include w($sm){
        gap: 0;
        flex-direction: column;
    }
}
// .footer-col{
    
//     // ------------media-------------
//     @include w($sm){
//         &.open{

//             .footer-list{
//                 display: block;
//                 padding-bottom: 20px;
//             }
//             .footer-title__plus::after {
//                 transform: rotate(0)
//             }
//         }
//     }
// }
.footer-subcol {
    margin-bottom: 45px;

    @include w($sm){
        margin-bottom: 0;
        &.open{

            .footer-list{
                display: block;
                padding-bottom: 20px;
            }
            .footer-title__plus::after {
                transform: rotate(0)
            }
        }
    }
}
.footer-list{
    line-height: 2.4;
    color: $light-2;
    // ------------media-------------
    @include w($sm){
        display: none;
    }
}
.footer-title{
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $light;
    margin-bottom: 16px;
    display: flex;
    // ------------media-------------
    @include w($sm){
        min-height: 45px;
        border-top: 1px solid #637b94;
        align-items: center;
        margin-bottom: 0;
    }
    &__plus{
        display: none;
        width: 20px;
        height: 20px;
        margin-left: auto;
        align-items: center;
        justify-content: center;
        position: relative;
        // ------------media-------------
        @include w($sm){
            display: flex;
        }
        &::after,&::before {
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            background-color: #fff;
            transition: .2s ease-in-out
        }
        &::after {
            transform: rotate(90deg)
        }
    }
}




.mail-link{
    margin-top: 16px;
    line-height: 2em;
    margin-bottom: 32px;
    display: inline-block;
    // ------------media-------------
    @include w($sm){
        margin: 20px 0;
        text-transform: uppercase;
        font-size: 10px;
    }
}

.social-list{
    display: flex;
    align-items: center;
    margin-left: -8px;
    margin-right: -8px;
    li{
        margin: 0 8px;
    }
    // ------------media-------------
    @include w($sm){
        justify-content: center;
    }
}
.social-link{
    opacity: 0.8;
    transition: $t1 ease-in-out;
    display: inline-block;
    &:hover{
        opacity: 1;
    }
}


.mail-link{
    margin-top: 16px;
    line-height: 2em;
    margin-bottom: 32px;
    display: inline-block;
    // ------------media-------------
    @include w($sm){
        margin: 20px 0;
        text-transform: uppercase;
        font-size: 10px;
    }
}

.social-list{
    display: flex;
    align-items: center;
    margin-left: -8px;
    margin-right: -8px;
    li{
        margin: 0 8px;
    }
    // ------------media-------------
    @include w($sm){
        justify-content: center;
    }
}
.social-link{
    opacity: 0.8;
    transition: $t1 ease-in-out;
    display: inline-block;
    &:hover{
        opacity: 1;
    }
}
.footer{
	// ------------media-------------
	@include w($lg){
		.container{
			max-width: 1150px;
		}
	}
}
.footer{
	// ------------media-------------
	@include w($sm){
		.container{
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.support-form-link {
	background-color: #4e6eb8;
	animation: support-form-link 0.3s ease-in-out forwards;
	position: fixed;
	bottom: 40px;
	right: 40px;
	height: 55px;
	width: 105px;
	border-radius: 50px;
	z-index: 1049; //from helpscout beacon origin
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: scale(0);
	transition: 0.3s ease-in-out;

	&:hover {
		background-color: #4260a6;
	}
    &.loading {
        animation: support-form-link-loading 0.4s ease-out alternate infinite;
    }
	&__icon {
		margin-right: 15px;
	}
    @include w($sm){
        bottom: 30px;
    }
}
@keyframes support-form-link-loading {
    from {
        background-color: #4e6eb8;
        transform: scale(1);
    } to {
        background-color: #698ee4;
        transform: scale(1)
    }
}

.link{
    position: relative;
    &::before{
        transition: $t1 ease-in-out;
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 0%;
        height: 2px;
        background: $blue;
    }
    &:hover{
        &::before{
            width: 100%;
        }
    }
}
@keyframes support-form-link {
	to {
		transform: scale(1)
	}
}
@media screen and (max-width: 768px) {
	body .hsds-beacon .BeaconFabButtonFrame {
		bottom: 100px;
		right: 15px;
	}
}
.features-section {
	padding: 130px 0;
	background: linear-gradient(180deg, #eaf6ff 0%, #eaf6ff 100%);
	&__btn {
		padding-top: 90px;
		text-align: center;
	}
	// ------------media-------------
	@include w($md){
		.col-md-6:last-child{
			margin: 0 auto;
		}
	}
	// ------------media-------------
	@include w($sm){
		.col-sm-12+.col-sm-12{
			margin-top: 50px;
		}
		&__btn {
			padding-top: 45px;
		}
	}
}
.features-item {
	text-align: center;
	padding: 0 50px;
	&__icon {
		margin: 0 auto;
		margin-bottom: 28px;
	}
	&__title {
		margin-bottom: 16px;
		white-space: nowrap;
	}
	// ------------media-------------
	@include w($sm){
		padding: 0;
	}
}
@import "/src/_includes/components/watch-video-button/styles/index.scss";

.portfolio-section {
	background-color: #e9f5fe;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	overflow: hidden;
	padding-top: 120px;
	padding-bottom: 65px;
	position: relative;
	.section-heading {
		p {
			max-width: 658px;
			margin: 0 auto;
		}
	}
	.disabled {
		display: none;
	}
	&__background {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		pointer-events: none;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}
.portfolio-laptop {
	position: relative;
	margin-top: -50px;
	// ------------media-------------
	@include w($sm){
		margin: 0 -30px;
		z-index: 1;
	}
}
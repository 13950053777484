.wallet-cards-section {
    padding: 120px 0;
    background: #F0F5FA;
    @include w(500px) {
        padding: 60px 0;
    }

    .container {
        margin: 0 auto 50px;

        @include w(880px) {
            margin: 0 auto;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 54px;
        line-height: 70px;
        color: #131313;
        padding-bottom: 25px;
        text-align: center;

        @include w(500px) {
            font-size: 34px;
            line-height: 40px;
            padding-bottom: 30px;
        }
    }
    &__desc {
        color: #637B94;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        padding-bottom: 40px;
        max-width: 800px;
        margin: 0 auto;

        @include w(500px) {
            font-size: 14px;
            line-height: 24px;
            padding-bottom: 30px;
        }
    }
    &__subtitle {
        font-weight: 500;
        font-size: 32px;
        line-height: 50px;
        color: #131313;
        padding-bottom: 50px;
        text-align: center;

        @include w(500px) {
            font-size: 22px;
            line-height: 32px;
            padding-bottom: 30px;
        }
    }
    &__body {
        display: grid;
        grid-template-columns: repeat(auto-fill, 152px);
        justify-content: center;
        gap: 30px;

        @include w(500px) {
            grid-template-columns: repeat(auto-fill, 90px);
            gap: 10px;
        }
    }
    &__card {
        background: #FFFFFF;
        border-radius: 40px;
        padding: 25px 20px;
        transition: 0.2s ease-in-out;

        &:hover {
            box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.04);
        }
        @include w(500px) {
            padding: 16px 14px;
            border-radius: 20px;
        }
    }
    &__card-wrapper {
        text-align: center; 

        @include w(500px) {
            & .icon {
                font-size: 2.4em;
            }
        }
    }
    // &__card-img {
    //     margin: 0 auto;
    //     width: 50px;
    //     border-radius: 50%;

    //     @include w($sm) {
    //         width: 40px;
    //     }
    // }
    &__card-name {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        color: #1F1F1F;
        padding-top: 20px;

        @include w(500px) {
            padding-top: 15px;
            font-size: 14px;
            line-height: normal;
        }
    }
}
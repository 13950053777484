@import "/src/styles/rebuild/splide-slider.scss";

.team-section {
	padding: 50px 0 80px;
	// ------------media-------------

	// .section-heading {
	// 	.title-1 {
	// 		display: none;
	// 	}
	// }

	&__slider {
		.splide__list {
			display: flex;
    		gap: 32px;
		}
		.splide__slide {
			flex-shrink: 1;
		}

		@include w($sm) {
			.splide__list {
				gap: unset;
			}
			.splide__slide {
				flex-shrink: 0;
			}
		}
		// position: relative;
		// .swiper-pagination{
		// 	padding-top: 40px;      
		// 	left: 0;
		// 	right: 0;  
		// 	&-bullet {
		// 		width: 10px;
		// 		height: 10px;
		// 		margin: 0 6px;
		// 		display: inline-block;
		// 		border-radius: 100%;
		// 		background: #fff;
		// 		border: 2px solid $blue;
		// 		opacity: 1;
		// 		&-active{
		// 			background: $blue;
		// 		}
		// 	}
		// }
		// .swiper-slide{
		// 	box-sizing: border-box;
		// 	height: auto;
		// }
		// .swiper-button-next,.swiper-button-prev{
		// 	width: 60px;
		// 	height: 60px;
		// 	background: #FFFFFF;
		// 	box-shadow: 0px 24px 36px rgba(23, 42, 63, 0.08);
		// 	border-radius: 50%;
		// 	// ------------media-------------
		// 	@include w($sm){
		// 		display: none;
		// 	}
		// }
		// .swiper-button-next{
		// 	right: -30px;
		// }
		// .swiper-button-prev{
		// 	left: -30px;
		// }
		// .swiper-button-prev:after, .swiper-button-next:after{
		// 	font-size: 20px;
		// 	// ------------media-------------
		// 	@include w($sm){
		// 		font-size: 15px;
		// 	}
		// }
	}
	@include minw($sm){
		padding: 50px 0;

		.swiper-button-prev,
		.swiper-button-next,
		.swiper-pagination{
			display: none;
		}
	}
}
.team-card {
	display: flex;
	flex-direction: column;
	padding: 48px 40px 55px;
	background: #f2f4f6;
	height: 100%;
	// ------------media-------------
	@include w($lg){
		padding: 20px;
	}
	&__avatar {
		width: 120px;
		// height: 200px;
		border-radius: 50%;
		margin: 0 auto;
		margin-bottom: 32px;
		// ------------media-------------
		@include w($lg){
			width: 120px;
			// height: 80px;
		}
	}
	&__name {
		font-weight: 500;
		margin-bottom: 16px;
		text-align: center;
	}
	&__info {
		margin-bottom: 32px;
	}
	&__links {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: auto;
		gap: 8px;
		// a {
		// 	margin: 0 8px;
		// }
	}
}
.download-section{
	background: $primary;
	padding: 65px 0;
	color: #fff;
	.title-1{
		color: #fff;
	}
	.mobile-platforms{
		justify-content: center;
	}
	// ------------media-------------
	@include w($sm){
		display: none;
	}
	&__latest{
		color: white;
		text-align: center;
	}
}
[data-download-link] {
	cursor: pointer;
}
.desktop-platforms{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 80px;
}
.check-hashsum{
	text-align: center;
	padding-top: 40px;
	a{
		line-height: 2;
		&:hover{
			text-decoration: underline;
		}
	}
}
.desktop-item{
	display: block;
	text-align: center;
	margin: 0 25px;
	@extend .download-hover-item;
	&__logo{
		margin-bottom: 25px;
		img{
			width: 86px;
			height: 86px;
		}
	}
}
.download-for-mobile{
	display: none;
	background:linear-gradient(79.25deg, #1E7AE6 0%, #2FA5F1 100%);
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
	min-height: 100vh;
	.title-1{
		color: #fff;
	}
	// ------------media-------------
	@include w($sm){
		display: block;
		.mobile-item__logo{
			border: none;
		}
	}
}
.latest-section {
	padding: 100px 0;

	&__banners {
		position: relative;
		.swiper-wrapper {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			column-gap: 30px;
		}
		.swiper-slide{
			padding-top: 30px;
			height: auto;
			overflow: hidden;
		}
		.swiper-pagination{
			padding-top: 40px;      
			left: -25px;
			right: 0;  
			&-bullet {
				width: 10px;
				height: 10px;
				margin: 0 6px;
				display: inline-block;
				border-radius: 100%;
				background: #fff;
				border: 2px solid $blue;
				opacity: 1;
				&-active{
					background: $blue;
				}
			}
		}
		.swiper-button-next,.swiper-button-prev{
			width: 60px;
			height: 60px;
			background: #FFFFFF;
			box-shadow: 0px 24px 36px rgba(23, 42, 63, 0.08);
			border-radius: 50%;

			&.swiper-button-disabled {
				opacity: .7;
			}
			// ------------media-------------
			@include w($sm){
				top: calc(50% + 15px);
				width: 44px;
				height: 44px;
			}
		}
		.swiper-button-prev{
			left: -25px;
		}
		.swiper-button-prev:after, .swiper-button-next:after{
			font-size: 20px;
			// ------------media-------------
			@include w($sm){
				font-size: 15px;
			}
		}

		.swiper-pagination, .swiper-button-next, .swiper-button-prev {
			display: none;
		}
	}
	.offer-section {
		display: flex;
		align-items: center;
		max-width: 420px;
		max-height: 200px;
		height: 100%;
		box-sizing: border-box;

		&--hot {
			&::before {
				left: 20px;
			}
		}
	}
	.offer-link {
		flex-wrap: nowrap;
		overflow: visible;
		height: 100%;
		padding: 50px;

		&__text {
			margin: 0;
			text-align: left;
			font-size: 30px;
		}
		&__img {
			margin-left: 20px;
		}
		.icon {
			margin-right: 0;
			margin-left: 20px;
		}
		@include w(1400px){
			padding: 50px;
		}
		@include w($lg) {
			&__text {
				font-size: 25px;
			}
			&__img {
				width: 50px;
			}
			.icon {
				font-size: 45px;
			}
		}
		@include w(1140px) {
			&__text {
				font-size: 20px;
			}
		}
		@include w($sm) {
			flex-direction: row;
		}
	}
	@include w(1000px) {
		&__banners {
			margin-right: -30px;

			.swiper-button-next, .swiper-button-prev {
				display: flex;
			}
			.swiper-pagination {
				display: block;
			}
			.swiper-wrapper {
				display: flex;
				flex-direction: row;
				column-gap: 0;
			}
			.swiper-slide {
				display: flex;
				justify-content: center;
			}
			.swiper-container-multirow>.swiper-wrapper {
				flex-direction: row;
			}
		}
		
		.offer-section {
			width: 100%;
			margin-right: 30px;
			height: 200px;
		}
	}

	@include w(430px) {
		.swiper-button-next, .swiper-button-prev {
			display: none;
		}
		
		.offer-link {
			padding: 30px;
			&__text {
				font-size: 18px;
			}
		}
	}
}
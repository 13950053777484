@import "/src/_includes/components/subscribe-form/styles/index.scss";

.newsletter-popup{
    width: 700px;
    height: 360px;
    overflow: hidden;
    box-shadow: 0px 17.7461px 36.9711px rgba(23, 42, 63, 0.2);
    border-radius: 2px;
    position: fixed;
    left: 30px;
    bottom: 80px;
    display: block;
    opacity: 0;
    background: #fff;
    z-index: -1;
    &.fadeIn{
        animation: newsletterPopupSlideIn .8s cubic-bezier(.68,-.6,.32,1.6) forwards;
    }
    // ------------media-------------
    @include w($md){
        display: none!important;
    }
    &.close{
        animation: newsletterPopupSlideOut .8s cubic-bezier(.68,-.6,.32,1.6) forwards;
        pointer-events: none;
    }
    &__part{
        display: flex;
        height: 100%;
    }
    &__left,
    &__right{
        flex: 0 0 50%;
		max-width: 50%;
    }
    &__left{
        position: relative;
        &::after{
            content: "";
            width: 1px;
            height: 100%;
            background: rgba(217, 220, 224, 0.5);
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    &__right{
        display: flex;
        align-items: center;
        justify-content: center;
        &-inner{
            padding: 0 20px;
            padding-top: 10px;
        }
    }
    &__close{
        @extend .modal-window__close;
    }
    &__title{
        padding-bottom: 10px;
    }
    &__subtitle{
        text-align: center;
        font-size: 15px;
        line-height: 1.6;
        color: $primary;
    }
    &__container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .btn-medium{
            font-size: 14px;
            width: 270px;
            margin: 5px 10px;
        }
    }
    &__start{
        padding: 25px 0;
    }
    .newsletter-social{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $primary;
        margin: 0 15px;
        padding: 7px 10px;
        transition: .1s;
        border-radius: 15px;
        will-change: transform;
        span{
            margin-left: 10px;
        }
        &:hover{
            background: #fff;
            transform: scale(1.05);
        }
    }
    &__finish{
        opacity: 0;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-footer,&-header{
            background: #EAF6FF;
        }
        &-header{
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                margin-right: 15px;
                margin-left: -50px;
            }
        }
        &-body{
            p{
                text-align: center;
                font-size: 16px;
                line-height: 2;
                padding-top: 10px;
                color: $primary;
            }
        }
        &-footer{
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .subscribe-form{
        flex-direction: column;
        padding-top: 18px;
        align-items: center;
        .subscribe-control{
            margin: 0px;
            height: 44px;
            max-width: 270px;
            &+.subscribe-control{
                margin-top: 20px;
            }
            &.subscribe-email{
                font-size: 18px;
            }
            &.subscribe-button{
                border-radius: 50vw;   
                font-size: 14px;
                text-transform: uppercase;
            }
        }
    }
    &--fadein, &--fadeout {
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
    }
    &--fadein {
        animation-name: fadein;
    }
    &--fadeout {
        animation-name: fadeout;
    }
}
@keyframes fadein {
    0% {
        opacity: 0;
        pointer-events: none;
        transform: translateY(0);
    } 60% {
        opacity: 1;
        pointer-events: none;
        transform: translateY(-100%);
    } 100% {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(-100%);
    }
}
@keyframes fadeout {
    0% {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0)
    } 40% {
        opacity: 0;
        pointer-events: none;
        transform: translateY(-100%)
    } 100% {
        opacity: 0;
        pointer-events: none;
        transform: translateY(-100%)
    }
}
@keyframes newsletterPopupSlideIn {
    0% {
        opacity: 0;
        transform: translateY(100px);
        z-index: 100;
    }

    to {
        opacity: 1;
        transform: translateX(0);
        z-index: 100;
    }
}

@keyframes newsletterPopupSlideOut {
    0% {
        opacity: 1;
        transform: translateX(0);
        z-index: 100;
    }
    99% {
        opacity: 0;
        transform: translateY(100px);
        z-index: 100;
    }
    100%{
        display: none!important;
    }
}
.social-links{
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 1px solid #CDD1D6;
	border-bottom: 1px solid #CDD1D6;
	padding: 32px 0;
	li{
		margin: 0 16px;
		div {
			&:hover{
				img{
					filter: brightness(.8);
				}
			}
			img {
				font-size: 0;
			}
		}
	}
}
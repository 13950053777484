$breakpoint-1:1700px;
$breakpoint-2:1500px;

.watch-video-button {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: $t1 ease;
    img {
        margin-bottom: 5px;

        @include w($xs) {
            width: 44px;
        }
    }
    span {
        color: $primary;
        background-color: #fff;
        border-radius: 10px;
        margin-top: 10px;
        padding: 3px 10px;
        line-height: 28px;
        font-size: 16px;

        @include w($xs) {
            font-size: 14px;
            line-height: 24px;
        }
    }
    &:hover {
        transform: translate(-50%, -50%) scale(1.05);
    }
    // ------------media-------------
    @include w($breakpoint-2){
        transform: translate(-50%, -50%) scale(0.8);
        &:hover {
            transform: translate(-50%, -50%) scale(0.85);
        }
    }

    &--blur {
        padding: 25px 35px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(5px);
        border-radius: 20px;

        span {
            color: #FFFFFF;
            background-color: transparent;
            padding: 0;
            margin-top: 0;
        }

        @include w($xs) {
            padding: 25px;
        }
    }
}
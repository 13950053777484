@import "./reset.scss";
@import "./variables";

@import "~nouislider/distribute/nouislider.min.css";
@import '../components/sprite-style.scss';
@import '../components/icons.scss';

[data-cards-loaded="false"] {
	display: none !important;
}
.downloads{
    margin-top: 1rem;
    padding: 1rem ;
    background: $dark-blue;
    color: #fff;
}
main{
    padding-top: 80px;

	@include w($xs) {
		padding-top: 50px;
	}
}
.for-mobile{
	display: none;
	// ------------media-------------
	@include w($sm){
		display: block;
	}
}
.btn{
	display: inline-flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	letter-spacing: 0.07em;
	text-transform: uppercase;
	transition: $t1 ease-in-out;
	font-weight: 500;
	line-height: 1.5;
	border-radius: 32px;
	i{
		transition: $t1 ease-in-out;
		margin-right: 10px;
	}
	&-big{
		@extend .btn;
		// width: 200px;
		padding: 0 50px;
		height: 45px;
		font-size: 16px;
	}
	&-medium{
		@extend .btn;
		// width: 200px;
		padding: 0 25px;
		height: 45px;
		font-size: 18px;
	}
	&-large{
		@extend .btn;
		min-width: 300px;
		padding: 0 40px;
		font-style: normal;
		font-size: 18px;
		height: 60px;
		// ------------media-------------
		@include w($sm){
			padding: 0 30px;
			min-width: 260px;
		}
	}
	&-blue{
		color: #fff;
		background: $blue;
		&:hover{
			box-shadow: $shadow;
			background: darken($blue, 5);
		}
	}
	&-white{
		background: #fff;
		color: $primary;
		&:hover{
			box-shadow: $shadow;
		}
	}
	&-outline{
		background: transparent;
		color: $blue;
		border: 2px solid $blue;
		&:hover{
			background: $blue;
			color: #fff;
			i{
				filter: grayscale(1) brightness(2);
			}
		}
	}
}
.btn-load-more{
	max-width: 216px;
	cursor: pointer;
	margin: 30px auto 0;
	border: 2px solid rgba(54, 146, 254, 0.24);
	border-radius: 60px;
	color: #3692FE;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	width: calc(100% - 30px);
	height: 60px;
	transition: all 0.3s;
	&:before{
		content: '';
		display: inline-block;
		background: url(/src/images/load.svg) no-repeat center;
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}
	&.loaded:before{
		animation: pulsing 1s linear infinite;
	}
}
.download-anchor {
	display: inline-flex !important;
	// ------------media-------------
	@include w($sm){
		display: none !important;
	}
}
.download-link {
	display: none !important;
	// ------------media-------------
	@include w($sm){
		display: inline-flex !important;
	}
}
.text-center {
	text-align: center!important;
}
.p-style{
	color: $parag;
	a, button:not([class^="btn"]){
		color: $blue;
	}
}
.p-18{
	@extend .p-style;
	font-size: 18px;
	line-height: 1.75;
	// ------------media-------------
	@include w($sm){
		font-size: 16px;
	}
}
.p-16{
	@extend .p-style;
	font-size: 16px;
	line-height: 2;
}
.download-hover-item{
	will-change: transform;
	transition: $t1 ease-in-out;
	// ------------off hover-------------
	@include offHoverOnDevices{
		&:hover{
			transform: scale(1.1);
			filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.5));
		}
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	justify-content: space-between;
}
.col {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
.col-auto {
	@extend .col;
	flex: 0 0 auto;
	width: auto;
	max-width: none;
}
.col-1 {
	@extend .col;
	flex: 0 0 8.333333%;
	max-width: 8.333333%;
}

.col-2 {
	@extend .col;
	flex: 0 0 16.666667%;
	max-width: 16.666667%;
}

.col-3 {
	@extend .col;
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	@extend .col;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}

.col-5 {
	@extend .col;
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
}

.col-6 {
	@extend .col;
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	@extend .col;
	flex: 0 0 58.333333%;
	max-width: 58.333333%;
}

.col-8 {
	@extend .col;
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}

.col-9 {
	@extend .col;
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	@extend .col;
	flex: 0 0 83.333333%;
	max-width: 83.333333%;
}

.col-11 {
	@extend .col;
	flex: 0 0 91.666667%;
	max-width: 91.666667%;
}

.col-12 {
	@extend .col;
	flex: 0 0 100%;
	max-width: 100%;
}

.col-md-6 {
	// ------------media-------------
	@include w($md){
		flex: 0 0 50%;
		max-width: 50%;
	}
}
.col-md-12{
	// ------------media-------------
	@include w($md){
		flex: 0 0 100%;
		max-width: 100%;
	}
}
.col-sm-12{
	// ------------media-------------
	@include w($sm){
		flex: 0 0 100%;
		max-width: 100%;
	}
}
.col-sm-6 {
	// ------------media-------------
	@include w($sm){
		flex: 0 0 50%;
		max-width: 50%;
	}
}
body{
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}
strong{
	font-weight: 700;
}
.container {
	max-width: 1340px;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;
	// ------------media-------------
	@include w($lg){
		max-width: 1100px;
	}
	// ------------media-------------
	@include w($md){
		padding-left: 30px;
		padding-right: 30px;
	}
	@include w($sm) {
		padding-left: 15px;
        padding-right: 15px;
	}
}
.section-heading{
	text-align: center;
	padding-bottom: 30px;
	[class^="p-"]{
		padding-top: 20px;

		@include w($sm) {
			padding-top: 8px !important;
		}
	
		@include w($xs) {
			margin-bottom: 16px;
		}
	}
}
.portfolio-section div.section-heading {
	text-align: center;

	@include w($sm) {
		padding-bottom: 0px;
	}
}
.title-style{
	color: $primary;
	font-weight: normal;
	text-align: center;
}
.title-1{
	@extend .title-style;
	font-size: 54px;
	// ------------media-------------
	@include w($lg){
		font-size: 48px;
	}
	// ------------media-------------
	@include w($sm){
		font-size: 28px;
	}
}
.title-2{
	@extend .title-style;
	font-size: 40px;
	// ------------media-------------
	@include w($sm){
		font-size: 24px;
	}
}
.title-3{
	@extend .title-style;
	font-size: 32px;
	// ------------media-------------
	@include w($sm){
		font-size: 20px;
	}
}
.title-4{
	@extend .title-style;
	font-size: 24px;
	// ------------media-------------
	@include w($sm){
		font-size: 18px;
	}
}
.title-5{
	@extend .title-style;
	font-size: 20px;
}
.hide-tablet{
	// ------------media-------------
	@include w($md){
		display: none !important;
	}
}
.hide-mobile{
	// ------------media-------------
	@include w($sm){
		display: none!important;
	}
}
i.icon-2x {
	display: block;
    font-size: 4.5em;
	// ------------media-------------
	@include w($lg){
		font-size: 4em;
	}
}
.image-component{
	padding-bottom: 100%;
	position: relative;
	&__image{
		display: block;
		max-width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		max-height: 100%;
	}
}
.hidden-link {
	cursor: pointer;
}
// .lozad-fade {
// 	animation-name: fade;
//   	animation-duration: 2s;
// }

// @keyframes fade {
// 	from {
// 	  opacity: 0;
// 	}
// 	to {
// 	  opacity: 1;
// 	}
// }

@keyframes pulsing {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@import "/src/_includes/components/social-links/styles/index.scss";
@import "/src/_includes/components/social-links-2/styles/index.scss";
@import "/src/_includes/components/header/styles/index.scss";
@import "/src/_includes/components/footer/styles/index.scss";
@import "/src/_includes/components/popup/styles/index.scss";
@import '/src/_includes/sections/wallet-cards-section/styles/index.scss';